import { combineReducers } from "redux";
import Layout from "./layout/LayoutReducer";
import ReservasDetalles from './reservasStore/reservasDetalles/reservasDetalles';
import BookingDetail from './bookingStore/bookingDetail/BookingDetailReduce';
import ActividadesReservas from "./reservasStore/actividadesReservas/ActividadesReservas";
import ActividadReservasDetalles from "./reservasStore/actividadReservaDetalles/ActividadReservaDetalles";
import ReservasOperaciones from './reservasStore/reservas/ReservasOperaciones';
import ReservasActions from './reservasStore/reservasActions/reservasActions';
import Catalogos from './catalogos/CatalogosReducer';
import Deckplan from './deckplan/DeckplanReduce';
import Booking from './bookingStore/booking/BookingReduce';
import CatalogoListaSeleccion from "./catalogos/CatalogoListaSeleccionReducer";
import ReservasHistorico from "./reservasStore/reservasHistorico/ReservasHistoricoRedux";
import Authenticate from "./auth/AuthReducer";
const rootReducers = combineReducers({
    ActividadesReservas,
    ActividadReservasDetalles,
    Authenticate,
    Booking,
    BookingDetail,
    CatalogoListaSeleccion,
    Catalogos,
    Deckplan,
    Layout,
    ReservasActions,
    ReservasDetalles,
    ReservasHistorico,
    ReservasOperaciones,
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
