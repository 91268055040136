import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useMsal } from '@azure/msal-react';
import { notifications } from '../../../helpers/notifications';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { RootDispatch } from '../../../store';
import { logout } from '../../../store/auth/AuthReducer';
import { EntidadDropDown } from './EntidadDropDown';
import { MonedaDropDown } from './MonedaDropDown';

const ProfileMenu = () => {
    const [menu, setMenu] = useState(false);
    const { user } = useSelector((state: RootState) => state.Authenticate);
    const { fullName, foto, entidades, entidadLegalIdPreferida, monedaIdPreferida, usuarioId } = user;
    const dispatch: RootDispatch = useDispatch();
    const { instance } = useMsal();

    const handleLogout = () => {
        dispatch(logout());
        instance.logoutRedirect().catch(e => {
            notifications({ ex: e });
        });
    };

    return (
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
            <DropdownToggle className='btn header-item ' id='page-header-user-dropdown' tag='button'>
                <img className='rounded-circle header-profile-user' src={foto} alt='Header Avatar' />
                <span className='d-none d-xl-inline-block ms-2 me-1'>{fullName}</span>
                <i className='mdi mdi-chevron-down d-none d-xl-inline-block' />
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem header>
                    <i className='bx bx-server font-size-16 align-middle me-1' />
                    <span>Entidad</span>
                </DropdownItem>
                <EntidadDropDown entidades={entidades} entidadPreferida={entidadLegalIdPreferida} usuarioId={usuarioId} />
                <div className='dropdown-divider' />
                <DropdownItem header>
                    <i className='bx bx-money font-size-16 align-middle me-1' />
                    <span>Moneda</span>
                </DropdownItem>
                <MonedaDropDown entidades={entidades} monedaPreferida={monedaIdPreferida} entidadPreferida={entidadLegalIdPreferida} />
                <div className='dropdown-divider' />
                <DropdownItem tag='a' className='dropdown-item' onClick={handleLogout}>
                    <i className='bx bx-power-off font-size-16 align-middle me-1 text-danger' />
                    <span>Salir</span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default ProfileMenu;
