import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { yeltNotification } from "../pages/Embarcaciones/Deckplan/helpers/DeckplanHelpers";
import { messageFormartError } from "./utilsMessageErrors";
import store from "../store";

interface Props {
    message?: string;
    title?: string;
    ex?: any;
    emailDefault?: string;
    seRegistra?: boolean;
    toastType?: 'info' | 'warning' | 'error' | 'success';
}
/**
 * muestra un toast con el mensaje correspondiente
 *  @params title?: string Opcional si define el titulo debe definir el mensaje y el toastType 
 *  @message?: string Opcional si define el mensaje debe definir el title y toastType
 *  @ex?: any Es opcional pero si envia este parametro debe ser la excepción del catch el cual mostrará si es un error un warning o un info.
 *  @seRegistra?: boolean  Se coloca en true, se registrará el mensaje en la campana de notificaciones
 *  @emailDefault: string Si el correo que se desea enviar es un correo determinado por el equipo de desarrollo
 *  @toastType?: string Se envia el tipo de toas a mostrar ejm info, warning, error si no envia texto se mostrará el success
 */
export const notifications = ({ title = '', message = '', seRegistra = false, ex, emailDefault, toastType }: Props) => {
    let email = emailDefault;
    if (emailDefault === undefined) {
        email = store.getState().Authenticate.user.email;
    }
    if (title !== '' && message !== '') {
        let dataNotification = {
            notifications: [
                {
                    subscriberId: email,
                    data: {
                        mensaje: message,
                    },
                },
            ],
        };
        switch (toastType) {
            case "info":
                toastr.info(message, title);
                break;
            case "warning":
                toastr.warning(message, title);
                break;
            case "error":
                toastr.error(message, title);
                yeltNotification(String(process.env.REACT_APP_URL_NOTIFICACION_ERRORES), dataNotification);
                break;
            default:
                toastr.success(message, title);
                break;
        }
        if (seRegistra) {
            yeltNotification(String(process.env.REACT_APP_URL_NOTIFICACION_EXITOS), dataNotification);
        }
    }
    if (ex !== undefined) {
        let dataNotificationError = {
            notifications: [
                {
                    subscriberId: email,
                    data: {
                        mensaje: `${messageFormartError(ex)}`,
                    },
                },
            ],
        };
        if (ex.response === undefined) {
            toastr.error(messageFormartError(ex), "Ups, algo salió mal");
            return;
        }

        if (ex.response.status >= 200 && ex.response.status < 300) {
            toastr.error(messageFormartError(ex), "Exito");
            yeltNotification(String(process.env.REACT_APP_URL_NOTIFICACION_EXITOS), dataNotificationError);
        }
        if (ex.response.status >= 400 && ex.response.status < 500) {
            toastr.warning(messageFormartError(ex), "Advertencia");
            yeltNotification(String(process.env.REACT_APP_URL_NOTIFICACION_ERRORES), dataNotificationError);
        }
        if (ex.response.status >= 500) {
            toastr.error(messageFormartError(ex), "Error");
            yeltNotification(String(process.env.REACT_APP_URL_NOTIFICACION_ERRORES), dataNotificationError);
        }
    }
}
