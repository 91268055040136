import moment from 'moment-timezone';
import 'moment/locale/es';

export const iso8601ToHHmm = (strDate: string) => {
    return moment(strDate, 'PTHHmm').format('HH:mm');
}

export const iso8601ToHHmmVuelos = (strDate: string) => {
    if (strDate !== null && strDate !== undefined) {
        if (strDate.includes('H')) {
            return moment(strDate, 'PTHHmm').format('HH:mm');
        } else {
            return moment(strDate, 'PTmm').format('00:mm');
        }
    }
}

export const converHHmmToPTHHMM = (strHHMM: string) => {
    const tiempo = strHHMM.split(':');
    return `PT${tiempo[0]}H${tiempo[1]}M`;
}

export const dateTimeToDate = (dateTime: string) => {
    return moment(dateTime).format('DD/MM/YYYY');
}

export const stringToDiaFecha = (fecha: string) => {
    return moment(fecha).format('ddd DD/MM/yyyy');
}

export const dateTimeToDateForms = (dateTime: string | Date) => {
    return moment(dateTime).format('YYYY-MM-DD');
}
export const iso8601Date = (dateTime: string | Date) => {
    return moment(dateTime).format('YYYY-MM-DDTHH:mm:ss.sss') + "Z";
}
export const dateTimeAuditoria = (dateTime: string | Date) => {
    return moment(dateTime).format('DD-MM-YYYY HH:mm:ss');
}

export const dateTimeAuditoriaLite = (dateTime: string | Date) => {
    return moment(dateTime).format('DD/MM/YY HH:mm');
}
export const dateToDDMMYYY = (date: Date) => {
    return moment(date).format('DD/MM/YYYY');
}
export const dateToNotificationFormat = (date: Date) => {
    return moment(date).format('DD-MM-YYYY');
}

export const dateTimeToHHmm = (date: Date) => {
    return moment(date).format('HH:mm');
}
export const dateToMMYYY = (date: Date) => {
    return moment(date).format('MM/YYYY');
}
export const dateToDDMM = (date: Date) => {
    return moment(date).format('DD/MM');
}

export const dateToYY_MM_DD = (fecha: Date) => {
    return moment(fecha).format('YYYY-MM-DD');
}
export const dateTimeMatriz = (dateTime: string | Date) => {
    return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
}
export const dateTimeMinutos = (dateTime: string | Date) => {
    return moment(dateTime).format('DD-MM-YYYY HH:mm');
}

export const dateToNameDay = (date: Date) => {
    const fecha = new Date(date);
    const nombreDia = fecha.toLocaleString('es-ES', { weekday: 'long' });
    return nombreDia.charAt(0).toUpperCase() + nombreDia.slice(1);
}

export const toStringToDate = (date: string) => {
    return moment(date).toDate();
}

export const stringToIsoString = (date: string) => {
    return moment(date).toISOString();
}

export const calcularDuracionByExpiracion = (fechaExpiracion: string): number => {
    let duracionYears = 1;
    const fechaActual = moment();
    const fechaExpiracionMoment = moment(fechaExpiracion);
    const duracion = moment.duration(fechaExpiracionMoment.diff(fechaActual));
    if (duracion.asYears() <= 1) {
        duracionYears = 2;
    } else {
        duracionYears = Math.ceil(duracion.asYears()) + 1;
    }
    return duracionYears;

}