import { DropdownItem } from 'reactstrap';
import { Entidad } from '../../../interfaces/respuestas';
import { ordenarEntidadesById } from '../../../helpers/utilsObject';
import { patchUserEntity } from '../../../helpers/apiUser';
interface Props {
    entidades: Entidad[];
    entidadPreferida: number | null;
    usuarioId: number;
}
export const EntidadDropDown = ({ entidades, entidadPreferida, usuarioId }: Props) => {
    const entidadesOrdenadas = ordenarEntidadesById(entidades);
    const handleClickEntidad = (entidad: Entidad) => {
        patchUserEntity(usuarioId, entidad.entidadLegalId);
    };
    return (
        <>
            {entidadesOrdenadas.map(e => (
                <DropdownItem key={e.entidadLegalId} tag='a' className='dropdown-item' onClick={() => handleClickEntidad(e)}>
                    <span>{e.nombre.toUpperCase()}</span>
                    {e.entidadLegalId === entidadPreferida && (
                        <span className='badge border border-light rounded-circle bg-success p-1'>
                            <span className='visually-hidden'>entidad actual</span>
                        </span>
                    )}
                </DropdownItem>
            ))}
        </>
    );
};
