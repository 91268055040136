import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROLE } from '../interfaces/roles';
import { hasRole } from '../helpers/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducers';

interface PrivateRouteProps {
    children: JSX.Element;
    roles: ROLE[];
}

const PrivateRoute: any = ({ children, roles = [] }: PrivateRouteProps) => {
    const location = useLocation();
    const { roles: userRoles, status } = useSelector((state: RootState) => state.Authenticate);

    try {
        if (status === 'authenticated') {
            if (hasRole(userRoles, roles)) {
                return children;
            } else {
                return <Navigate to='/prohibido' state={{ from: location }} replace />;
            }
        }
        if (status === 'checking' || status === 'not-authenticated') {
            return <Navigate to='/login' state={{ from: location }} replace />;
        }
    } catch (e) {
        return <Navigate to='/maintenance' state={{ from: location }} replace />;
    }
};

export default PrivateRoute;
