import { del, get, post } from '../../../../helpers/api_helper';
import { notifications } from "../../../../helpers/notifications";
import {
    Acomodacion,
    Assigned,
    CabinasInfo,
    EstrucutraArbol,
    ICabinaBloqueada, JsonData,
    PasajerosApi,
    PassengerToList
} from "../../../../interfaces/deckplan";
import store from '../../../../store';

export const UseGet = (url: string) => {
    return get(url).then(odata => {
        return odata.value;
    });
}

export const UseGetPassengers = (url: string) => {
    return get(url).then(odata => {
        return odata;
    });
}

export const lockCabinApi = (data: any) => {
    return post('BloqueosNumeroTipoCabina', data).then((_resp) => {
        notifications({ title: `Guardado`, message: 'Cabina bloqueada correctamente' });
        return _resp;
    }).catch(error => {
        notifications({ ex: error });
    });
}

export const unassignOnly = async (id: number) => {
    await del(`AsignacionesPasajeroCabinas(${id})`).then((_resp) => {
        console.log('')
    }).catch(error => {
        notifications({ ex: error });
    });
}

export const unlockCabinApi = async (id: number) => {
    await del(`/BloqueosNumeroTipoCabina(${id})`).then((_resp) => {
        notifications({ title: `Desbloqueo`, message: 'La cabina se desbloqueó correctamente' });
    })
        .catch(error => {
            notifications({ ex: error });
        });
}

export const yeltNotification = async (url: string, data: any) => {
    await post(url, data).then((_resp) => {
        console.log(_resp);
    })
        .catch(_error => {
            notifications({ ex: _error });
        });
}

export const correoCabinasRestantes = async (url: string, dataCorreo: any) => {
    await post(url, dataCorreo).then((_resp) => {
        notifications({ title: 'Éxito', message: `Correo enviado con éxito`, toastType: 'success' });
    }).catch(_error => {
        notifications({ ex: _error });
    });
}

export const changeClassCabin = (embarcacionState: Array<CabinasInfo>, cabina: number, cabFigClass: string, franjaFigClass: string) => {
    for (let i of embarcacionState) {
        if (i.Numero === cabina) {
            i.cantidadPasajeros = 0;
            i.cabFigClass = cabFigClass;
            i.franjaFigClass = franjaFigClass;
        }
    }
}

export const getExtension = (pasajeros: Array<PassengerToList>) => {
    let tempExtension: Array<string> = [];

    for (let i of pasajeros) {
        tempExtension.push(i.extension);
    }

    if (tempExtension.includes("T")) {
        return "T";
    } else {
        return "N";
    }
}

const estructuraArbol = (dataAgrupada: Object) => {
    let dataArbol = [];

    for (const [key] of Object.entries(dataAgrupada)) {
        let newBooking = {
            bookingCode: key,
            bookingDetail: []
        }
        dataArbol.push(newBooking)
    }
    return dataArbol;
}

const generarAcomodaciones = (i: EstrucutraArbol, j: PasajerosApi) => {
    let nuevaAcomodacion = {
        bookingDetailPosteriorId: j.bookingDetailPosteriorId,
        acomodacion: j.acomodacion,
        acomodacionDescripcion: j.acomodacionDescripcion,
        bookingCode: j.bookingCode,
        bookingDetailId: j.bookingDetailId,
        grupo: j.grupo,
        pasajeros: []
    }
    if (i.bookingCode === j.bookingCode) {
        if (i.bookingDetail.length > 0) {
            let existe = false;
            for (let k of i.bookingDetail) {
                if (JSON.stringify(k) === JSON.stringify(nuevaAcomodacion)) {
                    existe = true;
                }
            }
            if (!existe) {
                i.bookingDetail.push(nuevaAcomodacion);
            }
        } else {
            i.bookingDetail.push(nuevaAcomodacion)
        }
    }
    return i.bookingDetail;
}

export const createTreePassengers = async (url: string) => {
    let r = await UseGetPassengers(url);
    const result = r.reduce(function (q: any, a: any) {
        q[a.bookingCode] = q[a.bookingCode] || [];
        q[a.bookingCode].push(a);
        return q;
    }, Object.create(null));

    let dataFinal: EstrucutraArbol[] = estructuraArbol(result);
    for (const i of dataFinal) {
        for (const j of r) {
            i.bookingDetail = generarAcomodaciones(i, j);
        }
    }

    for (const i of dataFinal) {
        for (const j of i.bookingDetail) {
            for (const k of r) {
                if (k.bookingCode === i.bookingCode && k.bookingDetailId === j.bookingDetailId && k.acomodacion === j.acomodacion && k.grupo === j.grupo) {
                    let pasajero = {
                        pasajeroId: k.pasajeroId,
                        salidaEmbarcacionPosteriorId: k.salidaEmbarcacionPosteriorId,
                        tipoPasajero: k.tipoPasajero,
                        nombre: k.nombre,
                        apellido: k.apellido,
                        nacionalidad: k.nacionalidad,
                        titulo: k.titulo,
                        edad: k.edad,
                        grupo: k.grupo,
                        bookingDetailId: j.bookingDetailId,
                        bookingCode: k.bookingCode,
                        salesforceCode: k.salesforceCode,
                        extension: k.extension,
                        bookingDetailPosteriorId: j.bookingDetailPosteriorId
                    }
                    j.pasajeros.push(pasajero)
                }
            }
        }
    }

    return dataFinal;
}

export const groupBy = (list: Array<object>, keyGetter: any) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const tableEmailHtml = (dataGrouped: any) => {
    let tableEmail = "<div><table class='table-wrap is-striped' width='100%' border = '1'><tr><th>DECK</th><th>CABINAS LIBRES</th><th>CANTIDAD</th></tr>";
    dataGrouped.forEach((cabin: any) => {
        tableEmail += `
                <tr><td>${cabin.deck}</td><td>`;
        cabin.cabins.forEach((cab: any) => {
            if (cab.cantidadPasajeros === 0) {
                tableEmail += `${cab.TipoCabina.Deck.Sigla}-${cab.idCab}, `
            }
        })
        tableEmail += `</td><td>${cabin.cabins.length}</td></tr>`
    })
    tableEmail += "</table></div>"
    return tableEmail;
}

export const getAssignedPassengers = async (salidaId: number) => {
    let cabinasAsignadas = await UseGet(`/AsignacionesPasajeroCabinas?$expand=Pasajero,Cabina&$filter=Eliminado eq false and SalidaEmbarcacionId eq ${salidaId}`);
    let pasajerosAsignados: Assigned[] = [];
    for (let i of cabinasAsignadas) {
        let newCabin: Assigned = {
            numeroTipoCabinaId: i.NumeroTipoCabinaId,
            cabina: String(i.NumeroCabina),
            cabinaId: i.CabinaId,
            salidaEmbarcacionId: i.SalidaEmbarcacionId,
            isUpgrade: i.EsUpgradeDowngrade,
            extensionCrucero: i.ExtensionCrucero,
            cabinaOrigenId: i.CabinaOrigenId,
            pasajeros: [],
            cabinaAsignadaNombre: i.Cabina.Nombre,
        }
        pasajerosAsignados.push(newCabin);
    }

    let sinRepetidosPasajerosAsignados = pasajerosAsignados.filter((valorActual, indiceActual, arreglo) => {
        return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
    });
    for (let i of sinRepetidosPasajerosAsignados) {
        for (let j of cabinasAsignadas) {
            if (i.cabina === String(j.NumeroCabina)) {
                i.pasajeros.push({
                    asignacionPasajeroCabinaId: j.AsignacionPasajeroCabinaId,
                    pasajeroId: j.Pasajero.PasajeroId,
                    edad: j.EdadPasajero,
                    grupo: 0,
                    salesforceCode: "",
                    tipoPasajero: "",
                    nombre: j.Pasajero.Nombre,
                    apellido: j.Pasajero.Apellido,
                    nacionalidad: j.Pasajero.Nacionalidad,
                    titulo: j.Pasajero.Titulo,
                    bookingDetailId: j.BookingDetailId,
                    bookingCode: j.BookingCode,
                    extension: j.ExtensionCrucero,
                    salidaEmbarcacionPosteriorId: 0
                });
            }
        }
    }
    return sinRepetidosPasajerosAsignados;
};

export const getCabinasBloqueadas = (cabinas: CabinasInfo[], cabinasBloqueadas: ICabinaBloqueada[], claseCabina: string, claseFranja: string) => {
    if (cabinasBloqueadas.length > 0) {
        for (const i in cabinas) {
            for (const j in cabinasBloqueadas) {
                if (cabinas[i].NumeroTipoCabinaId === cabinasBloqueadas[j].NumeroTipoCabinaId) {
                    cabinas[Number(i)].cabFigClass = claseCabina;
                    cabinas[Number(i)].franjaFigClass = claseFranja;
                    cabinas[Number(i)].BloqueoNumeroTipoCabinaId = cabinasBloqueadas[j].BloqueoNumeroTipoCabinaId;
                }
            }
        }
        for (const i in cabinas) {
            if (cabinas[Number(i)].BloqueoNumeroTipoCabinaId === undefined) {
                cabinas[Number(i)].BloqueoNumeroTipoCabinaId = null;
            }
        }
    } else {
        for (const i in cabinas) {
            cabinas[Number(i)].BloqueoNumeroTipoCabinaId = null;
        }
    }
    return cabinas
}

export const handleDrag = (event: any, pasajeros: string, bookingDetailId: string, acomodacionDrag: string, bookingCode: string) => {
    event.dataTransfer.setData("pasajeros", pasajeros);
    event.dataTransfer.setData("bookingDetailId", bookingDetailId);
    event.dataTransfer.setData("accommodation", acomodacionDrag);
    event.dataTransfer.setData("bookingCode", bookingCode);
}

export const handleDragOver = (event: any) => {
    event.preventDefault();
}

export const handleAccommodations = (index: number, acomodacionqt: string, cabinas: CabinasInfo[]) => {
    let tiposAcomodaciones = [];
    for (let j of cabinas[index].TipoCabina.Cabina) {
        let tipoCamaCabina = j.Nombre.substring(j.Nombre.length - 1, j.Nombre.length);
        let tipoCamaPasajero = acomodacionqt.substring(acomodacionqt.length - 1, acomodacionqt.length);
        if (tipoCamaPasajero === tipoCamaCabina) {
            let acomodacionObj = {
                id: j.CabinaId,
                nombre: j.Nombre,
            }
            tiposAcomodaciones.push(acomodacionObj);
        }
    }
    return tiposAcomodaciones;
}

export const includesAcomodation = (arrayAcomodaciones: Array<Acomodacion>, accommodationqt: string) => {
    let incluedes: boolean = false;
    for (let i of arrayAcomodaciones) {
        if (i.nombre === accommodationqt) {
            incluedes = true;
            break;
        }
    }
    return incluedes;
}

export const handleGetJerarquia = (cabinas: CabinasInfo[], deck: string, tipo: string) => {
    let jerarquiaCabinaPasajero = 0;
    for (let i of cabinas) {
        if (i.TipoCabina.Sigla === tipo && i.TipoCabina.Deck.Sigla === deck) {
            jerarquiaCabinaPasajero = i.TipoCabina.Jerarquia;
            return jerarquiaCabinaPasajero;
        }
    }
}

export const handleGetAssignCabinId = (cabinas: CabinasInfo[], indice: number, acomodacionDrop: string) => {
    let assignCabinId = 0;
    for (let i of cabinas[indice].TipoCabina.Cabina) {
        if (i.Nombre === acomodacionDrop) {
            assignCabinId = i.CabinaId;
            return assignCabinId;
        }
    }
}

const handleUnirDatosCabinas = (dataOriginal: JsonData, newData: CabinasInfo[]) => {
    for (const i in newData) {
        for (const j in dataOriginal.data) {
            if (newData[parseInt(i)].Numero === dataOriginal.data[parseInt(j)].idCab) {
                newData[parseInt(i)] = { ...newData[parseInt(i)], ...dataOriginal.data[parseInt(j)] }
            }
        }
    }
    return newData;
}

export const coloresCabinasPinta = (dataCabinas: CabinasInfo, sigla: string) => {
    switch (sigla) {
        case 'L':
            dataCabinas.cabFigClass = 'pintaCabsBlue';
            break;
        case 'P':
            dataCabinas.cabFigClass = 'pintaCabsGreen';
            break;
        default:
            break;
    }
}

export const coloresCabinasIsabela = (data: CabinasInfo, s: string) => {
    switch (s) {
        case 'S':
            data.cabFigClass = 'isabelaCabsPink';
            break;
        case 'F':
            data.cabFigClass = 'isabelaCabsPink';
            break;
        case 'C':
            data.cabFigClass = 'isabelaCabsPurple';
            break;
        case 'O':
            data.cabFigClass = 'isabelaCabsSandyBrown';
            break;
        default:
            break;
    }
}

export const coloresCabinasSantaCruz = (newDataCabinas: CabinasInfo, sgl: string) => {
    switch (sgl) {
        case 'HE':
            newDataCabinas.cabFigClass = 'santaCruzCabsDarkBlue';
            break;
        case 'HF':
            newDataCabinas.cabFigClass = 'santaCruzCabsCadetBlue';
            break;
        case 'HV':
            newDataCabinas.cabFigClass = 'santaCruzCabsViolet';
            break;
        case 'EE':
            newDataCabinas.cabFigClass = 'santaCruzCabsCoral';
            break;
        case 'PE':
            newDataCabinas.cabFigClass = 'santaCruzCabsBrown';
            break;
        case 'PD':
            newDataCabinas.cabFigClass = 'santaCruzCabsGolden';
            break;
        default:
            break;
    }
}

export const filterCabins = async (url: string, embarcacion: string, salidaId: number, dataJson: JsonData) => {
    let newCabins: Array<CabinasInfo> = [];
    let dataApi = await UseGet(url);
    let cabinasAsignadas = await UseGet(`/AsignacionesPasajeroCabinas?$select=AsignacionPasajeroCabinaId,PasajeroId,CabinaId,NumeroTipoCabinaId,NumeroCabina,SalidaEmbarcacionId,EsUpgradeDowngrade,CabinaOrigenId,ExtensionCrucero&$filter=Eliminado eq false and SalidaEmbarcacionId eq ${salidaId}`);
    let cabinasBloqueadas = await UseGet(`/BloqueosNumeroTipoCabina?$filter=SalidaEmbarcacionId eq ${salidaId}`);

    newCabins = dataApi;

    handleUnirDatosCabinas(dataJson, newCabins);

    for (const i in newCabins) {
        let contPasajeros: number = 0;
        for (const j in cabinasAsignadas) {
            if (newCabins[parseInt(i)].Numero === cabinasAsignadas[parseInt(j)].NumeroCabina) {
                contPasajeros += 1;
                switch (cabinasAsignadas[parseInt(j)].EsUpgradeDowngrade) {
                    case 'U':
                        newCabins[parseInt(i)].franjaFigClass = 'cabsUpgrade';
                        break;
                    case 'D':
                        newCabins[parseInt(i)].franjaFigClass = 'cabsDowngrade';
                        break;
                    default:
                        break;
                }
                if (cabinasAsignadas[j].ExtensionCrucero === 'T' || cabinasAsignadas[j].ExtensionCrucero === 'S') {
                    newCabins[Number(i)].cabFigClass = 'cabsExtended';
                } else {
                    switch (embarcacion) {
                        case 'santaCruz':
                            coloresCabinasSantaCruz(newCabins[Number(i)], newCabins[parseInt(i)].TipoCabina.Deck.Sigla + newCabins[parseInt(i)].TipoCabina.Sigla);
                            break;
                        case 'isabela':
                            coloresCabinasIsabela(newCabins[Number(i)], newCabins[Number(i)].TipoCabina.Sigla);
                            break;
                        case 'pinta':
                            coloresCabinasPinta(newCabins[Number(i)], newCabins[Number(i)].TipoCabina.Sigla);
                            break;
                        default:
                            break;
                    }
                }
                newCabins[parseInt(i)].cantidadPasajeros = contPasajeros;
            }
        }
    }

    newCabins = getCabinasBloqueadas(newCabins, cabinasBloqueadas, 'cabsGrey', 'cabsBlock');

    return newCabins;
};

export const sendNotification = async (embarcacionNombre: string, fechaInicioSalida: string, fechaFinSalida: string, salidaEmbarcacionId: number, cabinas: CabinasInfo[], embarcacionLoad: string) => {
    let dataGrouped = [];
    const YELT_URL = process.env.REACT_APP_URL_NOTIFICACION_DECKPLAN;

    const email = store.getState().Authenticate.user.email;

    let urlEmbarcacion = '';

    switch (embarcacionLoad) {
        case 'santaCruz':
            urlEmbarcacion = 'santacruz';
            break;
        case 'isabela':
            urlEmbarcacion = 'isabela';
            break;
        case 'pinta':
            urlEmbarcacion = 'pinta';
            break;
        default:
            break;
    }

    let emails = [email, "sosorio@metropolitan-touring.com", "cmerizalde@metropolitan-touring.com"]

    let postBody = {
        "notifications": []
    };

    for (const i of emails) {
        let dataNotification = {
            "subscriberId": i,
            "data": {
                "embarcacion": embarcacionNombre,
                "fechaInicio": fechaInicioSalida,
                "fechaFin": fechaFinSalida,
                "urlDeckplan": `${process.env.REACT_APP_URL_FRONTEND}deckplan/${urlEmbarcacion}/${salidaEmbarcacionId}`
            }
        };
        // @ts-ignore
        postBody.notifications.push(dataNotification)
    }

    await yeltNotification(YELT_URL!, postBody);

    let grouped = groupBy(cabinas, (c: any) => c.TipoCabina.Deck.Sigla);

    switch (embarcacionLoad) {
        case 'santaCruz':
            let h = grouped.get('H').filter((cabin: any) => {
                return cabin.cantidadPasajeros === 0;
            });
            let p = grouped.get('P').filter((cabin: any) => {
                return cabin.cantidadPasajeros === 0;
            });
            let e = grouped.get('E').filter((cabin: any) => {
                return cabin.cantidadPasajeros === 0;
            });

            dataGrouped.push({ 'deck': 'H', 'cabins': h });
            dataGrouped.push({ 'deck': 'P', 'cabins': p });
            dataGrouped.push({ 'deck': 'E', 'cabins': e });
            break;
        case 'isabela':
            let u = grouped.get('U').filter((cabin: any) => {
                return cabin.cantidadPasajeros === 0;
            });
            let m = grouped.get('M').filter((cabin: any) => {
                return cabin.cantidadPasajeros === 0;
            });

            dataGrouped.push({ 'deck': 'U', 'cabins': u });
            dataGrouped.push({ 'deck': 'M', 'cabins': m });
            break;
        case 'pinta':
            let uPinta = grouped.get('U').filter((cabin: any) => {
                return cabin.cantidadPasajeros === 0;
            });

            dataGrouped.push({ 'deck': 'U', 'cabins': uPinta });
            break;
        default:
            break;
    }

    let tableEmail = tableEmailHtml(dataGrouped);

    let dataCorreo = {
        "producto": embarcacionNombre,
        "fechaOperacion": `${fechaInicioSalida} - ${fechaFinSalida}`,
        "htmlTableCabinasDisponibles": tableEmail
    }

    await correoCabinasRestantes("EnviarCorreoDeckplan/", dataCorreo);
}

export const validacionBloqueoSalidaSiguiente = async (newPasajeros: PassengerToList[], cabinas: CabinasInfo[], cabina: number) => {
    if (newPasajeros.filter(x => x.salidaEmbarcacionPosteriorId !== 0).length > 0) {
        let cabinaDroppedId = cabinas.filter(x => x.Numero === cabina)[0].NumeroTipoCabinaId;
        let cabinaSalidaSiguiente = await get(`BloqueosNumeroTipoCabina?$filter=NumeroTipoCabinaId eq ${cabinaDroppedId} and SalidaEmbarcacionId eq ${newPasajeros[0].salidaEmbarcacionPosteriorId}`);
        return cabinaSalidaSiguiente.value.length > 0;
    }
}

export const validacionCabinaAsignadaSalidaSiguiente = async (newPasajeros: PassengerToList[], cabinas: CabinasInfo[], cabina: number) => {
    if (newPasajeros.filter(x => x.salidaEmbarcacionPosteriorId !== 0).length > 0) {
        let cabinaDroppedId = cabinas.filter(x => x.Numero === cabina)[0].NumeroTipoCabinaId;
        let cabinaSalidaSiguiente = await get(`AsignacionesPasajeroCabinas?$filter=NumeroTipoCabinaId eq ${cabinaDroppedId} and SalidaEmbarcacionId eq ${newPasajeros[0].salidaEmbarcacionPosteriorId}`);
        return cabinaSalidaSiguiente.value.length > 0;
    }
}