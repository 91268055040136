import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootReducers from "./rootReducers";
import rootSaga from "./rootSagas";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore(
    {
        reducer: rootReducers,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            thunk: false,
            serializableCheck: false,
        }).concat(sagaMiddleware)
    }
)
sagaMiddleware.run(rootSaga);

export type RootDispatch = typeof store.dispatch;

export default store;