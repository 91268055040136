import { apiProductoPropioBloqueo, apiProveedorBloqueo } from "../constants/api";
import { BoletoBarcos, SendBloqueoData } from "../interfaces/bloqueos";
import { AsignacionProveedorActividad } from "../interfaces/asignacionProveedorActividad";
import { get, post, put } from "./api_helper"
import { notifications } from "./notifications";
import { messageFormartError } from "./utilsMessageErrors";
import { AsignacionProveedorActividadSend } from '../interfaces/sends';
import { ReservasPath } from "../interfaces/commons";


// GETS ============================================================================================
export const getProveedorBloqueo = () => {
    return get(apiProveedorBloqueo);
}

export const getProductoPropioBloqueo = () => {
    return get(apiProductoPropioBloqueo);
}

export const getGeografia = async () => {
    return get(`Geografias?$filter=EsBase eq true`);
}

export const getEspecialidadProveedor = async () => {
    return get(`ListasSeleccion?$select=ListaSeleccionId,Nombre&$filter=Ambito eq 'Proveedor.Especialidad.Experiencia' or Ambito eq 'Proveedor.Especialidad.Experiencia.GPS'`);
}

export const getListaSeleccionByAmbito = async (ambito: string) => {
    return get(`ListasSeleccion?$filter=Ambito eq '${ambito}'`);
}

export const getOrdenesTrabjo = async (url: string) => {
    return get(url);
}

export const getSalidaEmbarcaciones = async (url: string) => {
    return get(url);
}
export const getBussiness = (url: string) => {
    return get(url).then(res => {
        return res;
    });
};

export const getOrdenesPago = async (url: string) => {
    return get(url);
}

export const getData = async (url: string) => {
    return get(url)
        .catch(error => {
            notifications({
                title: `Falló al obtener los datos de ${url}`,
                message: messageFormartError(error),
                toastType: 'error'
            });
        });
}

export const getDocumentosProveedorById = async (proveedorId: number) => {
    return get(`DocumentosCursosProveedor?$filter=ProveedorId eq ${proveedorId} &$expand=Tipo,Proveedor,DocumentosCursos`)
        .then(resp => resp.value)
        .catch(error => {
            notifications({ title: 'Error', message: messageFormartError(error), toastType: 'error' });
            return [];
        })
}

export const getAllListaSeleccion = async () => {
    return get(`ListasSeleccion?filter = Ambito ne 'Productos.Feature'`);
}
export const getEstados = async () => {
    return get(`Estados`);
}
export const getReservasWizard = (estados: any): ReservasPath => {
    if (estados['Reserva'] === undefined) return {} as ReservasPath;
    const reservaPathForEstado: any = {
        actividades: { name: 'Actividades', estadosId: [], siglas: 'A', to: 'actividades', color: "RGB( 135, 206, 250)", colorInactivo: 'RGB(175, 246, 255)', contador: 'reservasBloqueadas' },
        pendiente: { name: 'Reserva Pendiente', estadosId: [estados['Reserva']['Reserva Pendiente'].EstadoId, estados['Reserva']['Modificación Pendiente'].EstadoId], siglas: 'RP', to: 'pendiente', color: "RGB(204, 178, 0)", colorInactivo: 'RGB(204, 204, 32)', contador: 'pendientes' },
        cancelacionPendiente: { name: 'Cancelación Pendiente', estadosId: [estados['Reserva']['Cancelación Pendiente'].EstadoId], siglas: 'CP', to: 'cancelacionPendiente', color: "RGB(255, 165, 0)", colorInactivo: 'RGB(255, 205, 40)', contador: 'cancelaciones' },
        reservado: { name: 'Reservado', estadosId: [estados['Reserva']['Reservado'].EstadoId], siglas: 'R', to: 'reservado', color: "RGB(60, 179, 113)", colorInactivo: 'RGB(100, 219, 153)' },
        cancelado: { name: 'Cancelado', estadosId: [estados['Reserva']['Cancelado'].EstadoId], siglas: 'C', to: 'cancelado', color: "RGB( 255, 99, 71)", colorInactivo: 'RGB(255, 139, 111)' },
        negado: { name: 'Negado', estadosId: [estados['Reserva']['Negado'].EstadoId], siglas: 'N', to: 'negado', color: "RGB( 128, 0, 32)", colorInactivo: 'RGB(168, 40, 72)' },
    }
    return reservaPathForEstado;
}

export const getBookingReservas = async () => {
    return get(`ReservasHistoricoBookingsBusiness`)
        .then(resp => resp)
        .catch(error => {
            notifications({ ex: error });
        })
}

export const getProveedoresReservas = async () => {
    return get(`ReservasHistoricoProveedoresBusiness`)
        .then(resp => resp)
        .catch(error => {
            notifications({ ex: error });
        })
}

// POSTS ============================================================================================
export const postAsignacionesProveedorActividades = async (data: AsignacionProveedorActividad) => {
    return post(`AsignacionesProveedorActividades`, data);
}

export const addNewAttach = async (data: string | Object) => {
    await post('MatrizAsignacionBusiness/Atar', data).then(() => {
        notifications({ title: `Atado Guardado`, message: 'Se ingresó correctamente' });
    })
        .catch(error => {
            notifications({ title: 'Falló el ingreso', message: messageFormartError(error), toastType: 'error' });
        });
}


export const generarBloqueos = (url: string, data: any) => {
    console.log(url, data);
    return post(url, data);
}

// PUSTS ============================================================================================
export const putBoletosEmbarcaciones = (url: string, dataItem: BoletoBarcos) => {
    const {
        BloqueoBoletoId,
        VueloIdaId,
        VueloRetornoId,
        EmbarcacionId,
        ProveedorId,
        EstadoId,
        FechaIda,
        FechaRetorno,
        CantidadOperativa,
        CantidadBloqueoTreintaDias,
        CantidadDevueltaTreintaDias,
        CantidadPrimeraEmision,
        CantidadBloqueoDiecisieteDias,
        CantidadDevueltaDiecisieteDias,
        CantidadUltimaHora,
        CantidadTotalEmitidos,
        CantidadDevueltaSieteDias,
        CantidadUsadosBloqueo,
        CantidadFueraBloqueo,
        SaldoBloqueo,
        CantidadTotalDevueltos,
        ConcresionAnual,
        ConcresionMensual,
        Comentario,
        Eliminado,
        SalidaEmbarcacionId,
        ComentarioPrimeraEmision,
        ComentarioUltimaHora,
    }: SendBloqueoData = dataItem;
    const dataSend: SendBloqueoData = {
        BloqueoBoletoId,
        CantidadBloqueoDiecisieteDias,
        CantidadBloqueoTreintaDias,
        CantidadDevueltaDiecisieteDias,
        CantidadDevueltaSieteDias,
        CantidadDevueltaTreintaDias,
        CantidadFueraBloqueo,
        CantidadOperativa,
        CantidadPrimeraEmision,
        CantidadTotalDevueltos,
        CantidadTotalEmitidos,
        CantidadUltimaHora,
        CantidadUsadosBloqueo,
        Comentario,
        ConcresionAnual,
        ConcresionMensual,
        Eliminado,
        EmbarcacionId,
        EstadoId,
        FechaIda,
        FechaRetorno,
        ProveedorId,
        SaldoBloqueo,
        SalidaEmbarcacionId,
        VueloIdaId,
        VueloRetornoId,
        ComentarioPrimeraEmision,
        ComentarioUltimaHora
    };
    return put(`${url}(${dataItem.BloqueoBoletoId})`, dataSend).then(() => {
        notifications({ message: 'El registro se actualizó', title: 'Actualizar registro', toastType: 'success' });
    }).catch(error => {
        notifications({ ex: error });
    })
}

export const putBoletosHoteles = (url: string, dataItem: any) => {
    const { inEdit, selected, ...rest } = dataItem;
    const dataSend = { ...rest };
    return put(`${url}(${dataSend.BloqueoBoletoHotelId})`, dataSend).then(() => {
        notifications({ message: 'El registro se actualizó', title: 'Actualizar registro', toastType: 'success' });
    }).catch(error => {
        notifications({ ex: error })
    })
}

export const putAsignacionActividadProveedor = (url: string, dataSend: AsignacionProveedorActividadSend, tipo: string) => {
    return put(`${url}(${dataSend.AsignacionProveedorActividadId})`, dataSend).then(() => {
        notifications({ message: `Se asignó como ${tipo}`, title: 'Actualización', toastType: 'info' });
    }).catch(error => {
        notifications({ ex: error });
    });
}

