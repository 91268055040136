/** RESERVAS OPERACIONES */
export const RESERVAS_OPERACIONES_LOADING = 'RESERVAS_OPS_LOADING';
export const RESERVAS_OPERACIONES_CLEAR = 'RESERVAS_OPS_CLEAR';

export const RESERVAS_OPERACIONES_GET_BY_ESTADO = 'RESERVAS_OPS_GET_BY_ESTADO';

export const RESERVAS_OPERACIONES_SET_DATA = 'RESERVAS_OPS_SET_DATA';
export const RESERVAS_OPERACIONES_UPDATE_DATA = 'RESERVAS_OPS_UPDATE_DATA';


export const RESERVAS_OPERACIONES_GET_SUCESORES = 'RESERVAS_OPS_GET_SUCESORES';
export const RESERVAS_OPERACIONES_SET_SUCESORES = 'RESERVAS_OPS_SET_SUCESORES';

export const RESERVAS_OPERACIONES_SELECTED_SET = 'RESERVAS_OPS_SELECTED_SET';
export const RESERVAS_OPERACIONES_SELECTED_DELETE = 'RESERVAS_OPS_SELECTED_DELETE';
export const RESERVAS_OPERACIONES_SELECTED_CLEAR = 'RESERVAS_OPS_SELECTED_CLEAR';

export const RESERVAS_OPERACIONES_START_STOP_PROCESS = 'RESERVAS_OPS_START/STOP_PROCESS';
export const RESERVAS_OPERACIONES_PROCESS_UNLOCK = 'RESERVAS_OPS_PROCESS_UNLOCK';

export const RESERVAS_OPERACIONES_GET_TOTALES = 'RESERVAS_OPS_GET_TOTALES';
export const RESERVAS_OPERACIONES_UPDATE_TOTALES = 'RESERVAS_OPS_UPDATE_TOTAL';
export const RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS = 'RE_OPS_UPDATE_TO_RE_BLOQUEADAS';


export const RESERVAS_OPERACIONES_ALL = 'RESERVAS_OPS_ALL';
export const RESERVAS_OPERACIONES_RESERVAS_ID_ALL = 'RESERVAS_OPS_RESERVAS_ID_ALL';

export const RESERVAS_OPERACIONES_ABRIR_LISTA_PASAJEROS = 'RESERVAS_OPS_ABRIR_LISTA_PASAJEROS';
export const RESERVAS_OPERACIONES_ENVIAR_LISTA_PASAJEROS = 'RESERVAS_OPS_ENVIAR_LISTA_PASAJEROS';
export const RESERVAS_OPERACIONES_SET_FILTRO_RESERVADO = 'RESERVAS_OPS_SET_FILTRO_RESERVADO';