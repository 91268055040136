import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './helpers/AppInsights';
import { Provider } from 'react-redux';
import { msalConfig } from './helpers/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { MsalProvider } from '@azure/msal-react';

const version = process.env.REACT_APP_VERSION;
export const msalInstance = new PublicClientApplication(msalConfig);

const style = `background: #222; color:${process.env.REACT_APP_PRODUCTION === 'true' ? '#3FCF59' : '#D9A42f'}; font-size:24px`;

if (process.env.REACT_APP_PRODUCTION === 'true') {
    console.log(`%c PROD`, style);
} else {
    console.log(`%c DEV`, style);
}
console.log(`%c Versión: ${version}`, style);

const app = (
    <BrowserRouter>
        <AppInsightsContext.Provider value={reactPlugin}>
            <Provider store={store}>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </Provider>
        </AppInsightsContext.Provider>
    </BrowserRouter>
);

render(app, document.getElementById('root'));
