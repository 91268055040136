import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { Layout } from '../layout/Layout';

import { ROLE } from '../interfaces/roles';

import { Prohibido } from '../pages/Utility/Prohibido';
import { Mantenimiento } from '../pages/Utility/Mantenimiento';

const AdministracionSalidas = lazy(() => import('../pages/Embarcaciones/SalidasEmbarcacion/containers/AdministracionSalidas'));
const Auditoria = lazy(() => import('../pages/Auditoria/Auditoria'));
const Booking = lazy(() => import('../pages/Booking/Booking'));
const Bloqueos = lazy(() => import('../pages/Bloqueos/index'));
const CatalogoProveedores = lazy(() => import('../pages/Administrator/CatalogoProveedores/CatalogoProveedores'));
const Deckplan = lazy(() => import('../pages/Embarcaciones/Deckplan/Main'));
const DetalleActividad = lazy(() => import('../pages/Embarcaciones/GestionEmbarcacion/Containers/DetalleActividad'));
const DetalleEmbarcacion = lazy(() => import('../pages/Embarcaciones/GestionEmbarcacion/Containers/Deck'));
const DetalleCabinas = lazy(() => import('../pages/Embarcaciones/GestionEmbarcacion/Containers/DetalleCabinas'));
const DetalleCatalogo = lazy(() => import('../pages/Administrator/CatalogoProveedores/DetalleCatalogo'));
const DetalleDecks = lazy(() => import('../pages/Embarcaciones/GestionEmbarcacion/Containers/DetalleDecks'));
const DetalleItinerarioEmbarcacion = lazy(() => import('../pages/Embarcaciones/GestionEmbarcacion/Containers/DetalleItinerarioEmbarcacion'));
const DetalleTipoCabinas = lazy(() => import('../pages/Embarcaciones/GestionEmbarcacion/Containers/DetalleTipoCabinas'));
const DetalleVehiculos = lazy(() => import('../pages/Administrator/CatalogoProveedores/DetalleVehiculos'));
const Disponibilidad = lazy(() => import('../pages/Embarcaciones/Disponibilidad/Disponibilidad'));
const GeneracionSalidas = lazy(() => import('../pages/Embarcaciones/SalidasEmbarcacion/containers/GeneracionSalidas'));
const Home = lazy(() => import('../pages/Home/Home'));
const ListadoEmbarcacion = lazy(() => import('../pages/Embarcaciones/GestionEmbarcacion/Containers/Embarcacion'));
const ListaSeleccion = lazy(() => import('../pages/Administrator/Catalogos/ListaSeleccion/Main'));
const Matriz = lazy(() => import('../pages/Operaciones/Matriz'));
const MatrizAsignacionBarcos = lazy(() => import('../pages/Operaciones/MatrizAsignacionBarcos/MatrizAsignacionBarcos'));
const MatrizPlanificacion = lazy(() => import('../pages/Operaciones/MatrizPlanificacion/index'));
const Ordenes = lazy(() => import('../pages/Reservas/GestionOrdenes/Main'));
const Reportes = lazy(() => import('../pages/Reportes/Reportes'));
const ReservaBoletos = lazy(() => import('../pages/Reservas/ReservaBoletos/Main'));
const Reservas = lazy(() => import('../pages/Reservas/Main'));
const TarifasProveedor = lazy(() => import('../pages/Administrator/TarifaProveedores/TarifasProveedor'));
const VisorReportes = lazy(() => import('../pages/Reportes/VisorReportes'));
const Vuelos = lazy(() => import('../pages/Administrator/AdministracionVuelos/Vuelos'));

export const AuthenticatedRoutes = () => {
    return (
        <Routes>
            <Route path='*' element={<Navigate to='/' />} />
            <Route path='/prohibido' element={<Prohibido />} />
            <Route path='/' element={<Layout Component={Home} />} />
            <Route
                path='/booking/*'
                element={
                    <PrivateRoute
                        roles={[
                            ROLE.AdministradorSistema,
                            ROLE.Operaciones,
                            ROLE.Reservas,
                            ROLE.Finanzas,
                            ROLE.Comercial,
                            ROLE.GerenciaComercial,
                            ROLE.PMTS,
                            ROLE.OperacionesMaritimas,
                            ROLE.YieldManagement,
                        ]}
                    >
                        <Layout Component={Booking} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/bloqueos/*'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Reservas]}>
                        <Layout Component={Bloqueos} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/catalogo-proveedores'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio, ROLE.Operaciones]}>
                        <Layout Component={CatalogoProveedores} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/catalogo-proveedores/vehiculo/:VehiculoProveedorId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio, ROLE.Operaciones]}>
                        <Layout Component={DetalleVehiculos} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/catalogo-proveedores/:ProveedorId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio, ROLE.Operaciones]}>
                        <Layout Component={DetalleCatalogo} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/deckplan/*'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Operaciones, ROLE.AsignacionPasajeroCabina]}>
                        <Layout Component={Deckplan} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/embarcaciones'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio, ROLE.YieldManagement, ROLE.Operaciones]}>
                        <Layout Component={ListadoEmbarcacion} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/embarcaciones/detalle-embarcacion/:embarcacionId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio, ROLE.YieldManagement, ROLE.Operaciones]}>
                        <Layout Component={DetalleEmbarcacion} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/embarcaciones/detalle-embarcacion/detalle-deck/:detalledeckId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement, ROLE.Operaciones]}>
                        <Layout Component={DetalleDecks} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/embarcaciones/detalle-embarcacion/detalle-deck/tipo-cabinas/:tipocabinaId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement, ROLE.Operaciones]}>
                        <Layout Component={DetalleTipoCabinas} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/embarcaciones/detalle-embarcacion/detalle-itinerario-embarcacion/:itinerarioId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement, ROLE.Operaciones]}>
                        <Layout Component={DetalleItinerarioEmbarcacion} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/embarcaciones/detalle-embarcacion/detalle-itinerario-embarcacion/detalle-actividad/:actividadId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement, ROLE.Operaciones]}>
                        <Layout Component={DetalleActividad} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/embarcaciones/detalle-embarcacion/detalle-deck/tipo-cabinas/detalle-cabinas/:detalleId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement, ROLE.Operaciones]}>
                        <Layout Component={DetalleCabinas} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/matriz'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Operaciones, ROLE.Reservas]}>
                        <Layout Component={Matriz} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/matriz-asignacion'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.OperacionesMaritimas]}>
                        <Layout Component={MatrizAsignacionBarcos} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/matriz-planificacion/*'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Operaciones, ROLE.OperacionesMaritimas]}>
                        <Layout Component={MatrizPlanificacion} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/ordenes/*'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Reservas, ROLE.Operaciones, ROLE.OperacionesMaritimas]}>
                        <Layout Component={Ordenes} />
                    </PrivateRoute>
                }
            />
            <Route path='/reservas/*' element={<Layout Component={Reservas} />} />
            <Route
                path='/reportes/reservas'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Reservas]}>
                        <Layout Component={Reportes} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/boletos/*'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Reservas, ROLE.GerenciaReservas, ROLE.OperacionesMaritimas]}>
                        <Layout Component={ReservaBoletos} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/salidas/disponibilidad/:salidaId'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement]}>
                        <Layout Component={Disponibilidad} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/salidas-admin'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement, ROLE.OperacionesMaritimas, ROLE.AsignacionPasajeroCabina]}>
                        <Layout Component={AdministracionSalidas} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/salidas'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.YieldManagement]}>
                        <Layout Component={GeneracionSalidas} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/tarifa-proveedores'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio]}>
                        <Layout Component={TarifasProveedor} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/visor-reportes/:nombreReporte'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.Reservas]}>
                        <Layout Component={VisorReportes} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/visor-reportes/:carpetaReporte/:nombreReporte'
                element={
                    <PrivateRoute
                        roles={[
                            ROLE.AdministradorSistema,
                            ROLE.AdministradorNegocio,
                            ROLE.Operaciones,
                            ROLE.Reservas,
                            ROLE.YieldManagement,
                            ROLE.Finanzas,
                            ROLE.Comercial,
                            ROLE.GerenciaComercial,
                            ROLE.PMTS,
                            ROLE.OperacionesMaritimas,
                        ]}
                    >
                        <Layout Component={VisorReportes} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/vuelos'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio]}>
                        <Layout Component={Vuelos} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/catalogos'
                element={
                    <PrivateRoute roles={[ROLE.AdministradorSistema, ROLE.AdministradorNegocio]}>
                        <Layout Component={ListaSeleccion} />
                    </PrivateRoute>
                }
            />
            <Route
                path='/auditoria'
                element={
                    <PrivateRoute
                        roles={[
                            ROLE.AdministradorSistema,
                            ROLE.AdministradorNegocio,
                            ROLE.Operaciones,
                            ROLE.Reservas,
                            ROLE.YieldManagement,
                            ROLE.Finanzas,
                            ROLE.OperacionesMaritimas,
                        ]}
                    >
                        <Layout Component={Auditoria} />
                    </PrivateRoute>
                }
            />
            <Route path='/mantenimiento' element={<Mantenimiento />} />
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    );
};
