import {
    ItemProveedor,
    MatrizSalida,
    ProveedorAsignadoEmbarcacion,
} from "../interfaces/matrizAsignacionSalida";
import moment from 'moment';
import { groupBy, State } from "@progress/kendo-data-query";
import { GridColumnMenuFilter, GridColumnMenuSort, } from "@progress/kendo-react-grid";
import { ROLE } from "../interfaces/roles";
import { ReservaDetalleEmitido } from "../interfaces/reservaDetalleEmitido";
import { Proveedor } from "../interfaces/proveedor";
import { IMatriz } from "../interfaces/matriz";
import { ActividadReserva } from "../interfaces/respuestas";
import { BookingCard } from "../pages/Reservas/components/BookingsCards";
import { Reserva } from "../interfaces/reserva";
import { Indicador, ObjetoDividido, ReservasPath } from "../interfaces/commons";
import { ListaSeleccionTree, Seleccion } from "../store/catalogos/CatalogosReducer";
import { ListaSeleccion } from "../interfaces/listaSeleccion";
import { getReservasTipoEstados, totalEstadosReserva } from "./reservasServices";

export const booleanToText = (value: boolean) => {
    return value ? 'Si'
        : 'No'
}

/**
 * Si el objeto está vacio retorna TRUE
 * @param {*} obj
 * @returns boolean
 */
export const esObjetoVacio = (obj: object) => {
    return (Object.keys(obj).length === 0)
}
/**
 * Retorna true o false dependiendo del estadoId en el que se encuentra
 * @param estadosId
 * @returns
 */
export const estadoReservaAcciones = (estadosId: number[], estadosReserva: any): boolean => {
    let respuesta = false;
    estadosId.forEach(e => {
        if (e === estadosReserva['Negado'].EstadoId || e === estadosReserva['Reservado'].EstadoId) {
            respuesta = false;
        }
        if (e === estadosReserva['Modificación Pendiente'].EstadoId || e === estadosReserva['Reserva Pendiente'].EstadoId
            || e === estadosReserva['Cancelación Pendiente'].EstadoId || e === estadosReserva['Lista de Espera'].EstadoId) {
            respuesta = true;
        }
    });
    return respuesta;
}

export const contains = (text: string, term: string) => {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
};

export const proveedorCheckNullNombreCompleto = (proveedor: []): Proveedor[] => {
    return proveedor.map((p: Proveedor) => ({
        ...p, NombreCompleto: p.NombreCompleto ?? 'No definido'
    }));
}

export const colorByTipo = (tipo: string) => {
    let color: string;
    switch (tipo) {
        case 'Charter':
            color = 'rgba(187,252,178,1)';
            break;
        case 'Dock':
            color = 'rgba(127,211,223,1)';
            break;
        case 'Close':
            color = 'rgba(226,216,169,1)';
            break;
        default:
            color = '';
            break;
    }
    return color;
}

export const colorCellGuiaSelected = (cell: ItemProveedor, dataItem: MatrizSalida, noHabilitado: boolean): string => {
    let color = '';
    if (noHabilitado) {
        color = 'orange';
    } else if (cell.estado === 1) {
        const proSelect = dataItem.proveedorAsignadoEmbarcacions.find(p => p.proveedorId === cell.proId);
        if (proSelect?.fechaInicio && proSelect?.fechaFin) {
            color = getColorByIntervalo(dataItem.duracionSalida, proSelect.fechaInicio, proSelect.fechaFin);
        } else {
            color = '#28a745';
        }
    }

    return color;
}

const getColorByIntervalo = (duracionSalida: number, fInicio: Date, fFin: Date): string => {
    let color = '';
    const duracionSelect = getDuracionDias(fInicio, fFin);
    if (duracionSelect < duracionSalida) {
        color = '#419AFF';
    }
    if (duracionSelect === duracionSalida) {
        color = '#28a745';
    }
    return color;
}

export const getDuracionDias = (inicio: Date, fin: Date): number => {
    const fechaInicio = moment(inicio);
    const fechaFin = moment(fin);
    return moment.duration(fechaFin.diff(fechaInicio)).asDays();
}

export const isColumnFilterActive = (field: string, dataState: State) => {
    return (
        GridColumnMenuFilter.active(field, dataState.filter) ||
        GridColumnMenuSort.active(field, dataState.sort)
    )
}

export const isFilterActive = (field: string, dataState: State) => {
    return isColumnFilterActive(field, dataState) ? 'active' : '';
}

export const sumaAsignados = (matrizActivaProcesando: MatrizSalida) => {
    let numGuias = 0;
    let numJefeExp = 0;
    let otros = 0;
    matrizActivaProcesando.proveedorAsignadoEmbarcacions
        .forEach((prov: ProveedorAsignadoEmbarcacion) => {
            switch (prov.tipoProveedor) {
                case 'Guía Embarcación':
                    numGuias++;
                    break;
                case 'Jefe Expedición':
                    numJefeExp++
                    break;
                default:
                    otros++
                    break;
            }
        });
    return { numGuias, numJefeExp, otros };
}

export const sumarSi = (isSumar: boolean, a: number, b: number): number => {
    return isSumar ? (a + b) : a;
}

export const todosProveedores = (dataArreglo: MatrizSalida[]) => {
    let todosJefesExp = '';
    let todosGuias = '';
    let todosFotografos = '';
    let otros = '';
    dataArreglo.forEach((data: MatrizSalida) => {
        let idiomaSelect = data.idioma;
        data.proveedorAsignadoEmbarcacions.forEach((prov: ProveedorAsignadoEmbarcacion) => {
            switch (prov.tipoProveedor) {
                case 'Guía Embarcación':
                    todosGuias += prov.nombre + '-' + idiomaSelect + '|';
                    break;
                case 'Jefe Expedición':
                    todosJefesExp += prov.nombre + '-' + idiomaSelect + '|';
                    break;
                case 'Fotógrafo':
                    todosFotografos += prov.nombre + '|';
                    break;
                default:
                    otros += prov.nombre + '|';
                    break;
            }
        })
    });

    return { todosJefesExp, todosGuias, todosFotografos, otros }
}

export const agruparPor = <T>(array: T[], predicate: (v: T) => any) =>
    array.reduce((acc, value) => {
        (acc[predicate(value)] ||= []).push(value);
        return acc;
    }, {} as { [key: string]: T[] });

export const isProveedorActivoByAll = (all: string[], selected: string): boolean => {
    return all.find(p => p === selected) === undefined;
}

export const agruparCatalogos = (data: any) => {
    const estadoAgrupado = groupBy(data, [{ field: 'Ambito' }]);
    let estados: any = {};
    estadoAgrupado.forEach((ambito: any) => {
        let detalle: any = {};
        ambito.items.forEach((estado: any) => {
            detalle[`${estado.Nombre}`] = { ...estado };
        });
        estados[`${ambito.value}`] = detalle;
    })
    return estados;
}

export const agruparListaSeleccionPorTabla = (data: Seleccion[]) => {
    const keys = Object.keys(data);
    const splitKeys = keys.map(k => k.split('.'));
    let resultado: Record<string, any> = {};

    splitKeys.forEach(lista => {
        let tempObj: Record<string, any> = resultado;
        lista.forEach((clave, index) => {
            if (!tempObj[clave]) {
                tempObj[clave] = {};
            }
            if (index < lista.length - 1) {
                tempObj = tempObj[clave];
            }
        });
    });

    return resultado;
}

export const agruparSalidas = (data: any) => {
    const salidasAgrupadas = groupBy(data, [{ field: 'salidaEmbarcacionId' }]);
    let result: any = {};
    salidasAgrupadas.forEach((sEmbarcacion: any) => {
        let detalle: any = {};
        let filas = 0;
        sEmbarcacion.items.forEach((se: any) => {
            const { salidaEmbarcacion, ...rest } = se;
            detalle = { ...rest };
            filas++;
        })
        result[`${sEmbarcacion.value}`] = { ...detalle, filas };
    });
    return result;
}

export const idNombre = (data: any, reservaWizard: ReservasPath) => {
    let idEstadoNombre: any = {};
    data.forEach((d: any) => {
        const siglas = d.Nombre.split(' ').map((s: string) => s.charAt(0).toLocaleUpperCase()).join('');
        idEstadoNombre[`${d.EstadoId}`] = {
            nombre: d.Nombre,
            siglas: siglas.substring(0, 3),
            color: nombreEstadoToColorReservaWizard(d.Nombre, reservaWizard),
            colorInactivo: nombreEstadoToColorReservaWizardInactivo(d.Nombre, reservaWizard)
        };
    });
    return idEstadoNombre;
}

export const idListaSeleccion = (lista: ListaSeleccion[]) => {
    let idLista: any = {};
    lista.forEach((l: ListaSeleccion) => {
        idLista[`${l.ListaSeleccionId}`] = l;
    });
    return idLista;
}

export const buildListaSeleccionTree = (lista: ListaSeleccion[]) => {
    let tree: ListaSeleccionTree[] = [];
    const agruparPorAmbito = groupBy(lista, [{ field: 'Ambito' }]);
    agruparPorAmbito.forEach((ambito: any) => {
        let items: ListaSeleccionTree[] = [];
        ambito.items.forEach((item: any) => {
            items.push({ text: item.Nombre, id: item.ListaSeleccionId, items: [] });
        });
        tree.push({ text: ambito.value, id: 0, items: items });
    });
    return tree;
}

const nombreEstadoToColorReservaWizard = (nombre: string, reservaWizard: ReservasPath): string => {
    let color = '';

    Object.keys(reservaWizard).forEach((key: string) => {
        if (reservaWizard[key].name === nombre) {
            color = reservaWizard[key].color;
        }
    });
    return color;
}
const nombreEstadoToColorReservaWizardInactivo = (nombre: string, reservaWizard: ReservasPath): string => {
    let color = '';
    Object.keys(reservaWizard).forEach((key: string) => {
        if (reservaWizard[key].name === nombre) {
            color = reservaWizard[key].colorInactivo;
        }
    });
    return color;
}


export const sumTotal = (item: any, field: string) => {
    return item.Disponibilidad.reduce((acc: any, current: { [x: string]: any; }) => acc + current[field], 0);
}
export const sumTotalPax = (item: any, field: string) => {
    for (let i in item.Disponibilidad) {
        item.Disponibilidad[i].TotalPaxAllotmentFooter = item.Disponibilidad[i].DisponibilidadRestanteAllotment * item.Disponibilidad[i].CantidadPasajeros
    }
    const sumPaxVendidas = item.Disponibilidad.map((c: any) => c.TotalPaxCabinaTomada).reduce((prev: any, curr: any) => prev + curr, 0)
    const sumPax = item.Disponibilidad.reduce((acc: any, current: {
        [x: string]: any;
    }) => acc + current['TotalPaxAllotmentFooter'], 0);
    return field === 'DisponibilidadRestanteAllotment' ? sumPax : item.Aforo - sumPax - sumPaxVendidas;
}

export const definirAmbito = (nombre = '', editar = false) => {
    let definicion;
    switch (nombre) {
        case "Experiencia":
            definicion = { title: `${editar ? 'Editar' : 'Añadir'} proveedor experiencia`, ambito: 'Experiencia' };
            break;
        case "Proveedor.Especialidad.Experiencia":
            definicion = { title: `${editar ? 'Editar' : 'Añadir'} proveedor experiencia`, ambito: 'Experiencia' };
            break;
        case "PMTS":
            definicion = { title: `${editar ? 'Editar' : 'Añadir'} proveedor PMTS`, ambito: 'PMTS' };
            break;
        case "Experiencia GPS":
            definicion = {
                title: `${editar ? 'Editar' : 'Añadir'} proveedor experiencia GPS`,
                ambito: 'Experiencia.GPS'
            };
            break;
        case "Proveedor.Especialidad.Experiencia.GPS":
            definicion = {
                title: `${editar ? 'Editar' : 'Añadir'} proveedor experiencia GPS`,
                ambito: 'Experiencia.GPS'
            };
            break;
        default:
            definicion = { title: `${editar ? 'Editar' : 'Añadir'} proveedor PMTS`, ambito: 'PMTS' };
            break;
    }
    return definicion;
}

export const esPMTS = (nombre: string) => {
    return nombre === undefined ? false : nombre === 'PMTS'
}

export const tieneAccesos = (userRoles: string[], accesosRoles: string[]): boolean => {
    let conAcceso = false
    let variosAccesos: boolean[] = [];
    userRoles.forEach(ur => {
        if (accesosRoles.includes(ur)) {
            variosAccesos.push(true);
        } else {
            variosAccesos.push(false);
        }
    });
    conAcceso = variosAccesos.includes(true);
    return conAcceso;
}

export const filtroEspecial = (ds: State) => {
    let buscarEspecial = tieneFiltroEspecial(ds);
    if (buscarEspecial !== null) {
        return `EjecutivoComercial/AsignacionEjecutivoOperativo/any(ao:contains(ao/AgenteOperativo/NombreCompleto,'${buscarEspecial}'))`;
    }
    return "";
}

const tieneFiltroEspecial = (ds: State): string => {
    if (ds.filter) {
        let filters: any = ds.filter.filters;
        for (const filtro of filters) {
            for (const subFiltro of filtro.filters) {
                if (subFiltro.field.includes("Personalizado")) {
                    return subFiltro.value;
                }
            }
        }
    }
    return "";
}

export const eliminarFiltroEspecial = (ds: State): State => {
    let newState = structuredClone(ds);
    if (newState.filter) {
        let filters: any = newState.filter.filters;
        for (let i = 0; i < filters.length; i++) {
            let encontro = false;
            for (let j = 0; j < filters[i].filters.length; j++) {
                if (filters[i].filters[j].field.includes("Personalizado")) {
                    filters[i].filters.splice(j, 1);
                    encontro = true;
                }
            }
            if (encontro) {
                if (filters[i].filters.length === 0) {
                    newState.filter.filters.splice(i, 1);
                }
            }
        }
        return newState;
    } else {
        return ds;
    }
}

export const fusionarFiltros = (dataString: string, stringFilter: string): string => {
    if (stringFilter === '') return dataString;
    let filtros = '';
    let newDataString = '';
    if (dataString.includes('filter')) {
        filtros = dataString.substring(dataString.indexOf('$filter='), dataString.indexOf('&$orderby'));
        newDataString = dataString.replace(filtros, `$filter=(${filtros.substring(8)} and ${stringFilter})`);
    } else {
        newDataString = `${dataString}&filter=${stringFilter}`;
    }
    return newDataString;
}

export const handlePermisosCarpeta = (nombreCarpeta: string) => {
    switch (nombreCarpeta) {
        case 'Boletos':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Reservas] };
        case 'Boletos Aéreos':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Reservas] };
        case 'Cruceros MT':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Reservas] };
        case 'Alojamiento':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Reservas] };
        case 'Hotel Finch Bay':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Reservas] };
        case 'Reservas':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Reservas] };
        case 'Comercial':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Comercial] };
        case 'Finanzas':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Finanzas] };
        case 'Guias Embarcaciones':
            return { accesos: [ROLE.AdministradorSistema, ROLE.OperacionesMaritimas] };
        case 'Operaciones Land':
            return { accesos: [ROLE.AdministradorSistema, ROLE.Operaciones, ROLE.Reservas] };
        case 'Yield':
            return { accesos: [ROLE.AdministradorSistema, ROLE.YieldManagement, ROLE.Reservas] };
        case 'Gerencia Comercial':
            return { accesos: [ROLE.AdministradorSistema, ROLE.GerenciaComercial] };
        case 'PMTS':
            return { accesos: [ROLE.AdministradorSistema, ROLE.PMTS] };
        case 'Auditoria':
            return { accesos: [ROLE.AdministradorNegocio, ROLE.Operaciones, ROLE.Reservas, ROLE.YieldManagement, ROLE.Finanzas, ROLE.OperacionesMaritimas, ROLE.AdministradorSistema] }
        default:
            return { accesos: [ROLE.AdministradorSistema] }
    }
}
// Función que extrae los códigos de reserva y los números de boletos de la tabla de reservas emitidas y remitidas.
// Esta función se utiliza para aplicar filtros en ambas pantallas.
export const extraerBookingCodes = (data: any) => {
    return data.map((item: any) => {
        const bookingCodes = JSON.stringify(item.ReservaDetalle.map((detalle: any) => detalle.Actividad.ActividadMaestra.BookingCode));
        const boletos = JSON.stringify(item.ReservaDetalle.map((detalle: any) => detalle.NumeroBoleto));
        return { ...item, bookingCodes, boletos };
    })
}

//Funcion utilizada para dar formato de fecha a las fechas de las reservas emitidas y remitidas
export const formatDateRB = (data: Array<ReservaDetalleEmitido>) => {
    return data.map((item: ReservaDetalleEmitido) => {
        item.AsignacionPasajero.Pasajero.FechaNacimiento = moment(item.AsignacionPasajero.Pasajero.FechaNacimiento).toDate()
        item.FechaInicio = moment(item.FechaInicio).toDate()
        item.FechaFin = moment(item.FechaFin).toDate()
        item.FechaEmision = moment(item.FechaEmision).toDate()
        return item
    })

}

export const hasRole = (userRoles: string[], requiredRoles: string[]) => {
    return requiredRoles.some(role => userRoles.includes(role));
};

export const desahabilitaAsignacionesActividad = (actividad: IMatriz[]): boolean => {
    if (actividad.length === 2) {
        return esTkTDirecto(actividad[0]) || esTkTDirecto(actividad[1]);
    }
    return true;
}
export const desahabilitaAsignacionActividad = (actividad: IMatriz): boolean => {
    if (!noEsGenerico(actividad)) {
        return false;
    }
    if (esTkTDirecto(actividad)) {
        return true;
    }
    return grupoEsNull(actividad);
}

const grupoEsNull = (actividad: IMatriz): boolean => {
    return actividad.grupo == null;
}

export const esOW = (actividad: IMatriz): boolean => {
    return actividad.serviceCode!.includes('OW');
}

export const esNewRT = (actividad: IMatriz): boolean => {
    return actividad.serviceCode!.includes('RT');
}

export const esNewOW = (actividad: IMatriz): boolean => {
    return esOW(actividad) && !grupoEsNull(actividad);
}

const noEsGenerico = (actividad: IMatriz): boolean => {
    if (actividad.serviceCode == null) return true;
    return (actividad.serviceCode.split('_')[0] !== 'GSC' && actividad.serviceCode.split('_')[0] !== 'GSN'
        && actividad.serviceCode.split('_')[0] !== 'LAN');
}

const esTkTDirecto = (actividad: IMatriz): boolean => {
    if (actividad.serviceCode == null) return true;
    return esTkT(actividad) && actividad.serviceCode.includes('DIR');
}

export const esTkT = (actividad: IMatriz): boolean => {
    if (actividad.serviceCode == null) return true;
    return actividad.serviceCode.includes('TKT');
}

export const tieneGrupo = (actividad: IMatriz): boolean => {
    return actividad.grupo !== 0;
}

export const bookingEnOperacion = (actividad: IMatriz): boolean => {
    return actividad.estadoBooking !== 'Operación';
}

export const actividadFechasIdenticasInicioFin = (actividad: IMatriz): boolean => {
    return actividad.fechaServicioInicio?.toString() !== actividad.fechaServicioFin?.toString()
}

export const sinEstado = (estado: number): boolean => {
    return estado === 0;
}

export const mapActividadReservaToBookingCard = (b: ActividadReserva): BookingCard => {
    return {
        ReservaId: b.actividadReservaCabeceraId,
        ActividadId: b.actividadId,
        Booking: {
            BookingId: b.bookingId,
            BookingCode: b.bookingCode,
            EjecutivoComercialNames: b.ejecutivoComercial,
            AgenteOperativoNames: b.agenteOperativo,
            BloqueoReserva: b.bloqueoReserva,
        },
        Cliente: {
            ClienteNames: b.nombreCliente,
        },
        Estado: {
            Nombre: b.estado,
            EstadoId: b.estadoId,
        },
        Proveedor: {
            NombreProveedor: b.nombreProveedor,
            ProveedorId: b.proveedorId,
        }
    }
}

export const mapBookingCardToActividadReserva = (b: BookingCard): ActividadReserva => {
    return {
        actividadId: b.ActividadId!,
        actividadReservaCabeceraId: b.ReservaId,
        agenteOperativo: b.Booking.AgenteOperativoNames,
        bloqueoReserva: b.Booking.BloqueoReserva,
        bookingCode: b.Booking.BookingCode,
        bookingId: b.Booking.BookingId,
        ejecutivoComercial: b.Booking.EjecutivoComercialNames,
        estado: b.Estado.Nombre,
        estadoId: b.Estado.EstadoId,
        nombreCliente: b.Cliente.ClienteNames,
        nombreProveedor: b.Proveedor!.NombreProveedor,
        proveedorId: b.Proveedor!.ProveedorId,
        selected: b.Seleccionado!,
    }
}

export const mapReservaToBookingCard = (r: Reserva): BookingCard => {
    return {
        ReservaId: r.ReservaId,
        ActividadId: 0,
        Booking: {
            BookingId: r.BookingId,
            BookingCode: r.Booking.BookingCode,
            EjecutivoComercialNames: r.Booking.EjecutivoComercialNames,
            AgenteOperativoNames: r.Booking.AgenteOperativoNames,
            BloqueoReserva: r.Booking.Bloqueado,
        },
        Cliente: {
            ClienteNames: r.Booking.ClienteNames,
        },
        Estado: {
            Nombre: r.Estado.Nombre,
            EstadoId: r.EstadoId,
        },
        Proveedor: {
            NombreProveedor: r.Proveedor.NombreCompleto,
            ProveedorId: r.ProveedorId,
        },
        Reserva: r,
    }
}

export const mapBookingCardToReserva = (b: BookingCard): Reserva => {
    return b.Reserva!;
}

export const widthPorcentToColumn = (porcent: number): number => {
    switch (porcent) {
        case 10:
            return 1;
        case 20:
            return 2;
        case 30:
            return 3;
        case 40:
            return 4;
        case 50:
            return 6;
        case 60:
            return 8;
        case 70:
            return 9;
        case 80:
            return 10;
        case 90:
            return 11;
        case 100:
            return 12;
        default:
            return 2;
    }
}

export const dividirGrupo = (elementos: Indicador[], cantidad = 3): ObjetoDividido => {
    const dimencion = Math.ceil(elementos.length / cantidad);
    return {
        ladoA: elementos.slice(0, dimencion),
        ladoB: elementos.slice(dimencion, 2 * dimencion),
        ladoC: elementos.slice(2 * dimencion),
    }
}

export const totalesReservasByEstados = async (estados: any) => {

    const reservationStateH = await getReservasTipoEstados('Hotel', [
        estados['Reserva']['Reserva Pendiente'].EstadoId,
        estados['Reserva']['Modificación Pendiente'].EstadoId,
        estados['Reserva']['Cancelación Pendiente'].EstadoId], '', estados['Reserva']);
    const rth = totalEstadosReserva(reservationStateH, estados['Reserva'], []);
    const reservationPendingHotel = rth?.rp ?? 0;
    const reservationStateF = await getReservasTipoEstados('Alimento', [
        estados['Reserva']['Reserva Pendiente'].EstadoId,
        estados['Reserva']['Modificación Pendiente'].EstadoId,
        estados['Reserva']['Cancelación Pendiente'].EstadoId], '', estados['Reserva']);
    const rtf = totalEstadosReserva(reservationStateF, estados['Reserva'], []);
    const reservationPendingFood = rtf?.rp ?? 0;

    return { reservationPendingHotel, reservationPendingFood }
}
