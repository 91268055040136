import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { notifications } from "../../helpers/notifications";
import { getAllListaSeleccion, getEstados } from "../../helpers/odataApiServices";
import { agruparCatalogos } from "../../helpers/utils";
import { CATALOGOS_ADD_LISTA_SELECCION, CATALOGOS_ADD_LISTA_SELECCION_DATA, CATALOGOS_GET_ESTADO, CATALOGOS_GET_LISTASELECCION, CATALOGOS_NEW_LISTA_SELECCION, CATALOGOS_SET_ESTADO, CATALOGOS_SET_IS_NEW, CATALOGOS_SET_LISTASELECCION, CATALOGOS_SET_LISTASELECCION_DATA, CAT_SET_AMBITO_SELECTED } from "./CatalogosTypes";
import { Action } from "../../interfaces/general";
import { postListaSeleccion } from "../../helpers/apiCatalogo";
import { RootState } from "../rootReducers";

/** revisar en LayoutSaga y ver si se elimina este proceso */
function* getCatalogoListaSeleccion(): any {
    try {
        const listaSeleccion = yield call(getAllListaSeleccion);
        yield put({ type: CATALOGOS_SET_LISTASELECCION_DATA, payload: listaSeleccion.value });
        const listaSeleccionAgrupado = yield call(agruparCatalogos, listaSeleccion.value);
        yield put({ type: CATALOGOS_SET_LISTASELECCION, payload: { listaSeleccionAgrupado } });
    } catch (error) {
        notifications({ ex: error });
    }
}

function* getCatalogoEstados(): any {
    try {
        const estados = yield call(getEstados);
        const estadosAgrupado = yield call(agruparCatalogos, estados.value);
        yield put({ type: CATALOGOS_SET_ESTADO, payload: estadosAgrupado });
    } catch (error) {
        notifications({ ex: error })
    }
}

function* postNewListaSeleccion({ payload }: Action<any>): any {
    const { listaSeleccion } = yield select((state: RootState) => state.Catalogos);
    try {
        const resp = yield call(postListaSeleccion, payload);
        const { Ambito, Descripcion, Eliminado, FechaCreacion, ListaSeleccionId, Nombre, Valor } = resp;
        yield put({ type: CAT_SET_AMBITO_SELECTED, payload: Ambito });
        const newListaSeleccion = {
            [Ambito]: {
                [Nombre]: {
                    Ambito,
                    Descripcion,
                    Eliminado,
                    FechaCreacion,
                    ListaSeleccionId,
                    Nombre,
                    Valor
                }
            }
        }

        if (listaSeleccion[Ambito]) {
            listaSeleccion[Ambito][Nombre] = {
                Ambito,
                Descripcion,
                Eliminado,
                FechaCreacion,
                ListaSeleccionId,
                Nombre,
                Valor
            }

            yield put({
                type: CATALOGOS_SET_LISTASELECCION,
                payload: listaSeleccion,
            });
        } else {
            yield put({
                type: CATALOGOS_ADD_LISTA_SELECCION,
                payload: newListaSeleccion,
            });
        }

        const newListaSeleccionData = {
            Ambito,
            Descripcion,
            Eliminado,
            FechaCreacion,
            ListaSeleccionId,
            Nombre,
            Valor
        }
        yield put({ type: CATALOGOS_ADD_LISTA_SELECCION_DATA, payload: newListaSeleccionData });

        notifications({
            title: "Nuevo catálogo",
            message: "Se ha creado un nuevo catálogo",
        });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: CATALOGOS_SET_IS_NEW, payload: false });
    }





}



function* watchGetCatalogoListaSeleccion() {
    yield takeEvery(CATALOGOS_GET_LISTASELECCION, getCatalogoListaSeleccion);
}

function* watchGetEstados() {
    yield takeEvery(CATALOGOS_GET_ESTADO, getCatalogoEstados);
}

function* watchNewListaSeleccion() {
    yield takeEvery(CATALOGOS_NEW_LISTA_SELECCION, postNewListaSeleccion);
}

export default function* CatalogosSaga() {
    yield all([
        fork(watchGetCatalogoListaSeleccion),
        fork(watchGetEstados),
        fork(watchNewListaSeleccion)
    ]);
}
