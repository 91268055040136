import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
    DECKPLAN_ABRIR_CABINA, DECKPLAN_ASIGNAR_CABINA,
    DECKPLAN_DESBLOQUEAR_CABINA,
    DECKPLAN_GET_PASAJEROS_LISTA,
    DECKPLAN_LOAD_DATA,
    DECKPLAN_SEND_YIELD_NOTIFICATION
} from "./actionsTypes";
import { RootState } from "../rootReducers";
import {
    Acomodacion, Assigned, BookingList,
    CabinasInfo,
    DataAsignar,
    ICabinaBloqueada,
    JsonData,
    LoadDeckplanData,
    StateDeckplan
} from "../../interfaces/deckplan";
import {
    setBloqueos,
    setCabinas,
    setDataSalida, setDisponibilidad, setLoading, setModalCabina,
    setModalYield,
    setPasajerosAsignados,
    setPasajerosLista, setVisible
} from "./actions";
import {
    changeClassCabin,
    coloresCabinasIsabela, coloresCabinasPinta,
    coloresCabinasSantaCruz,
    createTreePassengers,
    filterCabins,
    getAssignedPassengers, getExtension,
    sendNotification, unlockCabinApi
} from "../../pages/Embarcaciones/Deckplan/helpers/DeckplanHelpers";
import { urlIsabela, urlPinta, urlSantaCruz } from "../../constants/api";
import santaCruzCabins from "../../pages/Embarcaciones/Deckplan/Cabins/santaCruzCabins.json";
import pintaCabins from "../../pages/Embarcaciones/Deckplan/Cabins/pintaCabins.json";
import isabelaCabins from "../../pages/Embarcaciones/Deckplan/Cabins/isabelaCabins.json";
import { get, post } from "../../helpers/api_helper";
import { notifications } from "../../helpers/notifications";
import { dateTimeToDate } from "../../helpers/utilsDate";

const Deckplan = (state: RootState) => state.Deckplan;

const getPassengers = (url: string) => {
    return createTreePassengers(url);
}

const getDisponibilidadBySalidaId = (id: number) => {
    return get(`Disponibilidades?$select=CantidadCabinas,TotalCabinaTomada&$expand=TipoCabina($select=Sigla),Deck($select=Sigla)&$filter=SalidaEmbarcacionId eq ${id}`)
        .then(res => {
            return res.value;
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

const getDataSalidaById = (id: number) => {
    return get(`SalidasEmbarcacion?$select=FechaInicio,FechaFin&$expand=Embarcacion($select=Nombre)&$filter=SalidaEmbarcacionId eq ${id}`)
        .then(res => {
            return res.value[0];
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

const getDataBloqueosBySalida = (salidaId: number) => {
    return get(`BloqueosNumeroTipoCabina?$filter=SalidaEmbarcacionId eq ${salidaId}`)
        .then(res => {
            return res.value;
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

const getCabinaOriginalByNombre = (nombre: string) => {
    return get(`Cabinas?$select=CabinaId,Nombre&$filter=Nombre eq '${nombre}'`)
        .then(res => {
            return res.value[0].CabinaId;
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}

function* getDataSalida(id: number): any {
    const salida = yield call(getDataSalidaById, id);
    let data = {
        FechaInicio: dateTimeToDate(salida.FechaInicio),
        FechaFin: dateTimeToDate(salida.FechaFin),
        Nombre: salida.Embarcacion.Nombre
    }
    yield put(setDataSalida(data));
}

function* generateYieldNotification(embarcacionNombre: string, fechaInicioSalida: string, fechaFinSalida: string, salidaEmbarcacionId: number, cabinas: CabinasInfo[], embarcacionLoad: string): any {
    yield put(setModalYield(true));
    yield call(sendNotification, embarcacionNombre, fechaInicioSalida, fechaFinSalida, salidaEmbarcacionId, cabinas, embarcacionLoad);
    yield put(setModalYield(false));
}

function* getPasajerosEmbarcacion(salidaEmbarcacionId: number): any {
    const listaPasajeros = yield call(getPassengers, `PasajeroEmbarcacion(${salidaEmbarcacionId})`);
    yield put(setPasajerosLista(listaPasajeros.sort((a: BookingList, b: BookingList) => {
        return ('' + a.bookingCode).localeCompare(b.bookingCode)
    })));
}

function* getCabinas(salidaEmbarcacionId: number, embarcacion: string, url: string, dataJson: JsonData): any {
    const cabinas = yield call(filterCabins, url, embarcacion, salidaEmbarcacionId, dataJson);
    yield put(setCabinas(cabinas));
}

function* getPasajerosAsignados(salidaEmbarcacionId: number): any {
    const pasajerosAsignados = yield call(getAssignedPassengers, salidaEmbarcacionId);
    yield put(setPasajerosAsignados(pasajerosAsignados));
}

function* getBloqueos(salidaEmbarcacionId: number): any {
    const bloqueos = yield call(getDataBloqueosBySalida, salidaEmbarcacionId);
    yield put(setBloqueos(bloqueos));
}

function* getDisponibilidad(salidaEmbarcacionId: number): any {
    const disponibilidad = yield call(getDisponibilidadBySalidaId, salidaEmbarcacionId);
    yield put(setDisponibilidad(disponibilidad));
}

function* desbloquearCabinaByNumeroTipoCabina(numeroTipoCabinaId: number, indiceCabina: number, cabinas: CabinasInfo[], claseCabina: string, claseFranja: string, bloqueos: ICabinaBloqueada[]): any {
    let cabinasDesbloqueadas = cabinas;
    cabinasDesbloqueadas[indiceCabina].cabFigClass = claseCabina;
    cabinasDesbloqueadas[indiceCabina].franjaFigClass = claseFranja;
    const bloqueo = bloqueos.find(x => x.NumeroTipoCabinaId === numeroTipoCabinaId)
    yield call(unlockCabinApi, bloqueo!.BloqueoNumeroTipoCabinaId);
    yield put(setCabinas(cabinasDesbloqueadas));
    yield put(setBloqueos(bloqueos.filter(x => x.NumeroTipoCabinaId !== numeroTipoCabinaId)));
}

function* abrirCabinaByNumero(cabina: number): any {
    const { cabinas, pasajerosAsignados, bloqueos }: StateDeckplan = yield select(Deckplan);
    yield put(setVisible(true));
    let indexPasajero = pasajerosAsignados.findIndex(x => x.cabina === String(cabina));
    let indexCabina = cabinas.findIndex(x => x.Numero === cabina);
    let bloqueo: ICabinaBloqueada[] = [];
    if (cabinas[indexCabina].BloqueoNumeroTipoCabinaId !== null) {
        bloqueo = bloqueos.filter(x => x.NumeroTipoCabinaId === cabinas[indexCabina].NumeroTipoCabinaId);
    }
    if (indexPasajero === -1) {
        yield put(setModalCabina({
            numero: cabina,
            isUpgrade: '',
            pasajeros: [],
            bloqueo: cabinas[indexCabina].BloqueoNumeroTipoCabinaId !== null ? bloqueo : [],
            cabina: ''
        }))
    } else {
        yield put(setModalCabina({
            numero: cabina,
            isUpgrade: pasajerosAsignados[indexPasajero].isUpgrade,
            pasajeros: pasajerosAsignados[indexPasajero].pasajeros,
            bloqueo: cabinas[indexCabina].BloqueoNumeroTipoCabinaId !== null ? bloqueo : [],
            cabina: pasajerosAsignados[indexPasajero].cabinaAsignadaNombre
        }))
    }
}

function* asignar(dataAsignar: DataAsignar, cabinas: CabinasInfo[], initialData: LoadDeckplanData, dataAcomodaciones: Acomodacion[], pasajerosAsignados: Assigned[]): any {
    const newCabinas = [...cabinas];

    yield put(setLoading(true));

    const { salidaEmbarcacion, embarcacion } = initialData;
    let extension = yield call(getExtension, dataAsignar.pasajerosDrop);
    let assignOriginId = yield call(getCabinaOriginalByNombre, dataAsignar.acomodacionDrop);

    if (dataAsignar.pasajerosDrop.filter(x => x.extension === 'T' || x.extension === 'S').length > 0) {
        newCabinas[dataAsignar.indiceCabina].cabFigClass = 'cabsExtended';
    } else {
        switch (embarcacion) {
            case 'santaCruz':
                yield call(coloresCabinasSantaCruz, newCabinas[dataAsignar.indiceCabina], newCabinas[dataAsignar.indiceCabina].TipoCabina.Deck.Sigla + newCabinas[dataAsignar.indiceCabina].TipoCabina.Sigla);
                break;
            case 'isabela':
                yield call(coloresCabinasIsabela, newCabinas[dataAsignar.indiceCabina], newCabinas[dataAsignar.indiceCabina].TipoCabina.Sigla);
                break;
            case 'pinta':
                yield call(coloresCabinasPinta, newCabinas[dataAsignar.indiceCabina], newCabinas[dataAsignar.indiceCabina].TipoCabina.Sigla);
                break;
            default:
                break;
        }
    }

    switch (dataAsignar.esUpgradeDowngrade) {
        case 'D':
            newCabinas[dataAsignar.indiceCabina].franjaFigClass = 'cabsDowngrade';
            break;
        case 'U':
            newCabinas[dataAsignar.indiceCabina].franjaFigClass = 'cabsUpgrade';
            break;
        case 'N':
            assignOriginId = null;
            break;
        default:
            break;
    }

    newCabinas[dataAsignar.indiceCabina].cantidadPasajeros += dataAsignar.pasajerosDrop.length;

    yield put(setCabinas([...newCabinas]));

    let newCabina = {
        numeroTipoCabinaId: newCabinas[dataAsignar.indiceCabina].NumeroTipoCabinaId,
        cabina: String(dataAsignar.cabinaDropped),
        cabinaId: dataAsignar.droppedCabinaId,
        salidaEmbarcacionId: salidaEmbarcacion,
        isUpgrade: dataAsignar.esUpgradeDowngrade,
        extensionCrucero: extension,
        cabinaOrigenId: assignOriginId,
        pasajeros: dataAsignar.pasajerosDrop,
        cabinaAsignadaNombre: dataAsignar.esUpgradeDowngrade === 'D' || dataAsignar.esUpgradeDowngrade === 'U' ? dataAcomodaciones.filter(x => x.id === dataAsignar.droppedCabinaId)[0].nombre : dataAsignar.acomodacionDrop
    };

    let indexCabina = pasajerosAsignados.findIndex(x => x.cabina === String(dataAsignar.cabinaDropped));

    if (indexCabina === -1) {
        pasajerosAsignados.push(newCabina);
        yield put(setPasajerosAsignados([...pasajerosAsignados]));
    } else {
        pasajerosAsignados[indexCabina].pasajeros.push(...dataAsignar.pasajerosDrop);
        yield put(setPasajerosAsignados([...pasajerosAsignados]));
    }

    let indexToDb = pasajerosAsignados.findIndex(x => x.cabina === String(dataAsignar.cabinaDropped));

    try {
        for (let i of pasajerosAsignados[indexToDb].pasajeros) {
            if (i.asignacionPasajeroCabinaId === undefined) {
                let objToDb = {
                    PasajeroId: i.pasajeroId,
                    EdadPasajero: i.edad,
                    CabinaId: pasajerosAsignados[indexToDb].cabinaId,
                    NumeroTipoCabinaId: pasajerosAsignados[indexToDb].numeroTipoCabinaId,
                    NumeroCabina: pasajerosAsignados[indexToDb].cabina,
                    SalidaEmbarcacionId: pasajerosAsignados[indexToDb].salidaEmbarcacionId,
                    esUpgradeDowngrade: pasajerosAsignados[indexToDb].isUpgrade,
                    CabinaOrigenId: pasajerosAsignados[indexToDb].cabinaOrigenId,
                    ExtensionCrucero: pasajerosAsignados[indexToDb].extensionCrucero,
                    SalidaEmbarcacionExtensionId: i.salidaEmbarcacionPosteriorId,
                    BookingCode: i.bookingCode,
                    BookingDetailId: i.bookingDetailId,
                }
                let apiRes = yield call(post, 'AsignacionesPasajeroCabinas', objToDb)
                i.asignacionPasajeroCabinaId = apiRes.AsignacionPasajeroCabinaId;
            }
        }

        for (let i of pasajerosAsignados[indexToDb].pasajeros) {
            if (pasajerosAsignados[indexToDb].extensionCrucero === 'T') {
                let objToDb = {
                    PasajeroId: i.pasajeroId,
                    EdadPasajero: i.edad,
                    CabinaId: pasajerosAsignados[indexToDb].cabinaId,
                    NumeroTipoCabinaId: pasajerosAsignados[indexToDb].numeroTipoCabinaId,
                    NumeroCabina: pasajerosAsignados[indexToDb].cabina,
                    SalidaEmbarcacionId: i.salidaEmbarcacionPosteriorId,
                    esUpgradeDowngrade: pasajerosAsignados[indexToDb].isUpgrade,
                    CabinaOrigenId: pasajerosAsignados[indexToDb].cabinaOrigenId,
                    ExtensionCrucero: 'S',
                    SalidaEmbarcacionExtensionId: null,
                    BookingCode: i.bookingCode,
                    BookingDetailId: i.bookingDetailPosteriorId,
                }
                yield call(post, 'AsignacionesPasajeroCabinas', objToDb)
            }
        }

        notifications({ title: `Éxito`, message: 'Se ingresó correctamente', toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
        yield call(changeClassCabin, newCabinas, Number(pasajerosAsignados[indexToDb].cabina), 'cabsWhite', 'cabsUp');
        let newPasajerosAsignados = pasajerosAsignados.splice(pasajerosAsignados.length, 1);
        yield put(setPasajerosAsignados([...newPasajerosAsignados]));
    } finally {
        yield call(getPasajerosEmbarcacion, salidaEmbarcacion);
        yield put(setLoading(false));
    }
}

function* getLoadData() {
    const { initialData }: StateDeckplan = yield select(Deckplan);
    const { salidaEmbarcacion, embarcacion } = initialData;
    yield fork(getPasajerosEmbarcacion, salidaEmbarcacion);
    let url = '';
    let jsonData = {} as JsonData;
    switch (embarcacion) {
        case 'santaCruz':
            url = urlSantaCruz;
            jsonData = santaCruzCabins;
            break;
        case 'isabela':
            url = urlIsabela;
            jsonData = isabelaCabins;
            break;
        case 'pinta':
            url = urlPinta;
            jsonData = pintaCabins;
            break;
        default:
            break;
    }
    yield fork(getCabinas, salidaEmbarcacion, embarcacion, url, jsonData);
    yield fork(getDataSalida, salidaEmbarcacion);
    yield fork(getPasajerosAsignados, salidaEmbarcacion);
    yield fork(getBloqueos, salidaEmbarcacion);
    yield fork(getDisponibilidad, salidaEmbarcacion);
}

function* getPasajerosData() {
    const { initialData }: StateDeckplan = yield select(Deckplan);
    const { salidaEmbarcacion } = initialData;
    yield fork(getPasajerosEmbarcacion, salidaEmbarcacion);
}

function* sendYield() {
    const { initialData, datosSalida, cabinas }: StateDeckplan = yield select(Deckplan);
    const { salidaEmbarcacion, embarcacion } = initialData;
    const { Nombre, FechaInicio, FechaFin } = datosSalida;
    yield fork(generateYieldNotification, Nombre, FechaInicio.replace(/\//g, '-'), FechaFin.replace(/\//g, '-'), salidaEmbarcacion, cabinas, embarcacion);
}

function* desbloquearCabina(action: { type: string, payload: number }) {
    const { cabinas, bloqueos }: StateDeckplan = yield select(Deckplan);
    let indexCabins = cabinas.findIndex(x => x.Numero === action.payload);
    yield fork(desbloquearCabinaByNumeroTipoCabina, cabinas[indexCabins].NumeroTipoCabinaId, indexCabins, cabinas, 'cabsWhite', 'cabsUp', bloqueos);
}

function* abrirCabina(action: { type: string, payload: number }) {
    yield fork(abrirCabinaByNumero, action.payload);
}

function* asignarCabina() {
    const {
        dataAsignar,
        cabinas,
        initialData,
        dataAcomodaciones,
        pasajerosAsignados
    }: StateDeckplan = yield select(Deckplan);
    yield fork(asignar, dataAsignar, cabinas, initialData, dataAcomodaciones, pasajerosAsignados);
}

function* watchDeckplanLoadData() {
    yield takeEvery(DECKPLAN_LOAD_DATA, getLoadData);
}

function* watchDeckplanGetPasajeros() {
    yield takeEvery(DECKPLAN_GET_PASAJEROS_LISTA, getPasajerosData);
}

function* watchDeckplanSendYieldNotificaction() {
    yield takeEvery(DECKPLAN_SEND_YIELD_NOTIFICATION, sendYield);
}

function* watchDeckplanDesbloquearCabina() {
    yield takeEvery(DECKPLAN_DESBLOQUEAR_CABINA, desbloquearCabina);
}

function* watchDeckplanAbrirCabina() {
    yield takeEvery(DECKPLAN_ABRIR_CABINA, abrirCabina);
}

function* watchDeckplanAsignarCabina() {
    yield takeEvery(DECKPLAN_ASIGNAR_CABINA, asignarCabina);
}

function* deckplanSaga() {
    yield all([
        fork(watchDeckplanLoadData),
        fork(watchDeckplanGetPasajeros),
        fork(watchDeckplanSendYieldNotificaction),
        fork(watchDeckplanDesbloquearCabina),
        fork(watchDeckplanAbrirCabina),
        fork(watchDeckplanAsignarCabina),
    ])
}

export default deckplanSaga;