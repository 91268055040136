import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { RootState } from '../../rootReducers';
import { loadingAR, setActividadReservasData, setGeneratedStart } from './actions';
import {
    contarActividadesBloqueadas,
    getActividadReservaBusiness,
    getReservasTipoEstados,
    multiProcessGenerarReservas,
    postGenerarReservas
} from '../../../helpers/reservasServices';
import { notifications } from '../../../helpers/notifications';
import {
    ACTIVIDAD_RESERVA_CLEAR,
    ACTIVIDAD_RESERVA_GET,
    ACTIVIDAD_RESERVA_START_GENERATED,
    ACTIVIDAD_RESERVA_SEARCH,
    ACTIVIDAD_RESERVA_IS_SEARCH,
    ACTIVIDAD_RESERVA_ALL_GENERATED,
    ACTIVIDAD_RESERVA_UPDATE_BLOQUEO
} from './types';
import {
    RESERVAS_ACTIONS_OPEN_GENERATED,
    RESERVAS_ACTIONS_OPEN_MULTI_BLOCK,
    RESERVAS_ACTIONS_SET_IS_PROCESSING_MULTI_BLOCK
} from '../reservasActions/type';
import { ACTIVIDAD_RESERVA_DETALLE_CLEAR } from '../actividadReservaDetalles/types';
import { setTotalesReservas } from '../reservas/reservasOperacionesSagas';
import { Action } from '../../../interfaces/general';
import { RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS } from '../reservas/types';

function* generarReservasBy(): any {
    const {
        ActividadesReservas,
        ActividadReservasDetalles,
        ReservasActions,
        Catalogos,
        ReservasOperaciones: { reservasBloqueadas }
    } = yield select((state: RootState) => state);
    const { selected } = ActividadesReservas;
    const { data } = ActividadReservasDetalles;
    const { tipo } = ReservasActions;
    const { estados } = Catalogos;
    try {
        yield put(setGeneratedStart(true));
        yield call(postGenerarReservas, selected, data);
        yield put({ type: ACTIVIDAD_RESERVA_UPDATE_BLOQUEO, payload: selected });
        const reservaData = yield call(getReservasTipoEstados, tipo, [
            estados['Reserva']['Reserva Pendiente'].EstadoId,
            estados['Reserva']['Modificación Pendiente'].EstadoId,
            estados['Reserva']['Cancelación Pendiente'].EstadoId], '', estados['Reserva']); // Tipo Alimento / Hotel
        yield call(setTotalesReservas, reservaData, []);
        yield put({ type: RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS, payload: reservasBloqueadas - 1 });
        yield put({ type: RESERVAS_ACTIONS_OPEN_GENERATED, payload: false });
        notifications({ title: 'Éxito', message: 'Bloqueado', toastType: 'success' });
    } catch (error) {
        yield put({ type: RESERVAS_ACTIONS_OPEN_GENERATED, payload: false });
        notifications({ ex: error });
    } finally {
        yield put(setGeneratedStart(false));
    }
}

function* getActividadesReservas({ payload }: Action<string>): any {

    try {
        yield put(loadingAR(true));
        yield put({ type: ACTIVIDAD_RESERVA_CLEAR });
        yield put({ type: ACTIVIDAD_RESERVA_DETALLE_CLEAR });

        const actividadReservas = yield call(getActividadReservaBusiness, payload);
        yield put(setActividadReservasData(actividadReservas));

        let arb = contarActividadesBloqueadas(actividadReservas);
        yield put({ type: RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS, payload: arb });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put(loadingAR(false));
    }
}

function* searchByPeriodo({ payload }: Action<any>): any {
    const { tipo } = yield select((state: RootState) => state.ReservasActions);
    const inicio = payload !== undefined ? payload.inicio : undefined;
    const fin = payload !== undefined ? payload.fin : undefined;
    try {
        yield put({ type: ACTIVIDAD_RESERVA_IS_SEARCH, payload: true });
        yield put({ type: ACTIVIDAD_RESERVA_CLEAR });
        yield put({ type: ACTIVIDAD_RESERVA_DETALLE_CLEAR });

        const actividadReservas = yield call(getActividadReservaBusiness, tipo, inicio, fin);
        yield put(setActividadReservasData(actividadReservas));

        let arb = contarActividadesBloqueadas(actividadReservas);
        yield put({ type: RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS, payload: arb });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: ACTIVIDAD_RESERVA_IS_SEARCH, payload: false });
    }
}

function* generarTodo(): any {
    try {
        yield put({ type: RESERVAS_ACTIONS_SET_IS_PROCESSING_MULTI_BLOCK, payload: true });
        const { ReservasActions, Catalogos } = yield select((state: RootState) => state);
        const { tipo, currentBookings } = ReservasActions;
        const { estados } = Catalogos;
        yield call(multiProcessGenerarReservas, currentBookings, tipo);
        yield put({ type: RESERVAS_ACTIONS_OPEN_MULTI_BLOCK, payload: false });
        yield put({ type: ACTIVIDAD_RESERVA_GET, payload: tipo });
        const reservaData = yield call(getReservasTipoEstados, tipo, [
            estados['Reserva']['Reserva Pendiente'].EstadoId,
            estados['Reserva']['Modificación Pendiente'].EstadoId,
            estados['Reserva']['Cancelación Pendiente'].EstadoId], '', estados['Reserva']);
        yield call(setTotalesReservas, reservaData, []);
        notifications({ title: 'Éxito', message: `Se bloquearon con éxito`, toastType: 'success' });
    } catch (error) {
        yield put({ type: RESERVAS_ACTIONS_OPEN_MULTI_BLOCK, payload: false });
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_SET_IS_PROCESSING_MULTI_BLOCK, payload: false });
    }
}

// observadores
function* watchAllGenerated() {
    yield takeLatest(ACTIVIDAD_RESERVA_ALL_GENERATED, generarTodo)
}

function* watchSearchReserva() {
    yield takeLatest(ACTIVIDAD_RESERVA_SEARCH, searchByPeriodo);
}

function* watchGenerarReservas() {
    yield takeEvery(ACTIVIDAD_RESERVA_START_GENERATED, generarReservasBy);
}

function* watchActividadesReservas() {
    yield takeEvery(ACTIVIDAD_RESERVA_GET, getActividadesReservas);
}

export default function* actividadesReservasSagas() {
    yield all([
        fork(watchGenerarReservas),
        fork(watchActividadesReservas),
        fork(watchSearchReserva),
        fork(watchAllGenerated),
    ]);
}