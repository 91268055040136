import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { showRightSidebarAction, toggleLeftmenu } from '../../store/actions';
import logo from '../../assets/svg/metropolitan-movil.svg';
// @ts-ignore
import NotificationCenter from 'react-notification-center-component';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import Navbar from './Navbar';
import { RootState } from '../../store/rootReducers';

const Header = (props: any) => {
    const appIdNotification = process.env.REACT_APP_ID_NOTIFICATION;
    const entornoPROD = process.env.REACT_APP_PRODUCTION === 'true' ? true : false;
    const { user } = useSelector((state: RootState) => state.Authenticate);
    const [isMenuOpened] = useState(false);
    const { navbarButtonVisible } = useSelector((state: RootState) => state.Layout);

    function toggleFullscreen() {
        const document: any = window.document;
        const element: any = document.Element;
        /* alternative standard method */
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    return (
        <header id='page-topbar'>
            <div className='navbar-header'>
                <div className='d-flex'>
                    <div className='navbar-brand-box'>
                        <Link to='/' className='logo logo-light logo-dark'>
                            <span className='logo-sm'>
                                <img src={logo} alt='' height='22' />
                            </span>
                            <span className='logo-lg'>
                                <img src={logo} alt='' height='35' />
                            </span>
                        </Link>
                    </div>

                    <button
                        id={'navbarButton'}
                        type='button'
                        className='btn btn-sm px-3 font-size-16 d-lg-none header-item'
                        data-toggle='collapse'
                        onClick={() => {
                            props.toggleLeftmenu(!props.leftMenu);
                        }}
                        data-target='#topnav-menu-content'
                    >
                        <i className='fa fa-fw fa-bars' />
                    </button>
                </div>
                {/*Navbar para producción y si navbarButtonVisible es false debido a estilos de menu contraído*/}
                <div className='d-flex'>
                    {entornoPROD && !navbarButtonVisible ? (
                        <div className='d-lg-inline-block ms-1'>
                            <Navbar menuOpen={isMenuOpened} />
                        </div>
                    ) : (
                        <div className='d-flex justify-content-between align-items-center'>
                            {!navbarButtonVisible && (
                                <p className='font-size-20' style={{ color: 'white', fontWeight: 'bold' }}>
                                    Ambiente de pruebas
                                </p>
                            )}
                        </div>
                    )}

                    <div className='dropdown d-none d-lg-inline-block ms-1'>
                        <button
                            type='button'
                            className='btn header-item noti-icon '
                            onClick={() => {
                                toggleFullscreen();
                            }}
                            data-toggle='fullscreen'
                        >
                            <i className='bx bx-fullscreen' />
                        </button>
                    </div>

                    <div style={{ paddingTop: '1.3rem' }}>
                        <NotificationCenter className='dropdown d-none d-lg-inline-block ms-1' appId={appIdNotification} subscriberId={user?.email} />
                    </div>
                    <ProfileMenu />
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    leftMenu: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state: any) => {
    const { layoutType, showRightSidebar, leftMenu } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
})(Header);
