import { DropdownItem } from 'reactstrap';
import { Entidad } from '../../../interfaces/respuestas';
import { obtenerMonedaPorEntidad } from '../../../helpers/utilsObject';
interface Props {
    entidades: Entidad[];
    entidadPreferida: number | null;
    monedaPreferida: number | null;
}
export const MonedaDropDown = ({ entidades, monedaPreferida, entidadPreferida }: Props) => {
    if (entidadPreferida === null) entidadPreferida = 0;
    if (monedaPreferida === null) monedaPreferida = 0;
    const monedasByEntidadPreferida = obtenerMonedaPorEntidad(entidades, entidadPreferida);
    return (
        <>
            {monedasByEntidadPreferida.map(m => (
                <DropdownItem key={m.monedaId} tag='a' className='dropdown-item' disabled>
                    <span>{m.nombre}</span>
                    {m.monedaId === monedaPreferida && (
                        <span className='badge border border-light rounded-circle bg-success p-1'>
                            <span className='visually-hidden'>moneda actual</span>
                        </span>
                    )}
                </DropdownItem>
            ))}
        </>
    );
};
