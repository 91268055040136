import { Entidad, Moneda } from "../interfaces/respuestas";


export const itemList = (obj: Object, key: string) => {
    return Object.values(obj).map((o: any) => {
        return o[key];
    })
}

export const objectIsVoid = (obj: Object) => {
    return (Object.keys(obj).length === 0)
}

export const ordenarEntidadesById = (entidades: Entidad[]) => {
    return [...entidades].sort((a, b) => a.entidadLegalId - b.entidadLegalId);
}

export const obtenerMonedaPorEntidad = (entidades: Entidad[], entidadId: number): Moneda[] => {
    if (entidadId === 0) return [] as Moneda[];
    const entidad = entidades.find(e => e.entidadLegalId === entidadId);
    return entidad?.monedas || [] as Moneda[];
}
