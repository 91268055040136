import { all, call, put, takeLatest } from "redux-saga/effects";
import { AuthState, loginSuccess, setAvatar, setUsuarioSettings } from "./AuthReducer";
import { PayloadAction } from '@reduxjs/toolkit';
import { getAvatar } from "../../helpers/utilsUser";
import { notifications } from "../../helpers/notifications";
import { UsuarioSettings } from "../../interfaces/user";
import { getUserSettings, patchUser, postNewUser } from "../../helpers/apiUser";


function* loginSaga(action: PayloadAction<AuthState>): Generator<any, void, any> {
    const tokenGraph = action.payload.accessTokenGraph;
    const { user, roles } = action.payload;
    try {
        const foto = yield call(getAvatar, tokenGraph);
        yield put(setAvatar(foto));
        const userSettings: UsuarioSettings = yield call(getUserSettings, user);
        if (userSettings != null) {
            yield put(setUsuarioSettings(userSettings));
            yield put({ type: 'SET_IS_AUTHENTICATED', payload: true });
            yield call(patchUser, userSettings.usuarioId, user, roles);
        } else {
            const registerUser = yield call(postNewUser, user, roles);
            if (registerUser != null) {
                const userSettings: UsuarioSettings = {
                    usuarioId: registerUser.usuarioId,
                    esSA: false,
                    entidades: [],
                    entidadLegalIdPreferida: null,
                    monedaIdPreferida: null,
                }
                yield put(setUsuarioSettings(userSettings));
                yield put({ type: 'SET_IS_AUTHENTICATED', payload: true });
            } else {
                yield put({ type: 'SET_IS_AUTHENTICATED', payload: false });
                notifications({ ex: 'Error al registrar el usuario' });
            }
        }
    } catch (error) {
        yield put({ type: 'SET_IS_AUTHENTICATED', payload: false });
        notifications({ ex: error });
    }
}

function* watchLoginSuccess() {
    yield takeLatest(loginSuccess.type, loginSaga);
}


export default function* authSaga() {
    yield all([watchLoginSuccess()]);
}