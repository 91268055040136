import { AccountInfo } from "@azure/msal-browser";
import { User } from "../interfaces/user";
import { AuthState } from "../store/auth/AuthReducer";
import * as graph from '@microsoft/microsoft-graph-client';

const createUser = (account: AccountInfo, uniqueId: string): User => {
    return {
        uId: uniqueId,
        usuarioId: 0,
        fullName: account.name ?? 'Invitado',
        email: account.username,
        lastLogin: new Date().toISOString(),
        alias: account.username.substring(0, account.username.indexOf('@')),
        entidades: [],
        esSA: false,
        foto: '',
        entidadLegalIdPreferida: null,
        monedaIdPreferida: null
    };
};

export const createAuthState = (account: AccountInfo, accessToken: string, accessTokenGraph: string): AuthState => {
    const user = createUser(account, account.idTokenClaims?.sub ?? '');
    return {
        status: 'authenticated',
        user,
        roles: account.idTokenClaims?.roles || [],
        regional: false,
        accessToken,
        currentAccount: account,
        accessTokenGraph,
    };
};

export const getAvatar = async (tokenGraph: string) => {
    const client = graph.Client.init({
        authProvider: (done) => {
            done(null, tokenGraph)
        }
    });
    try {
        const result = await client.api('me/photos/48x48/$value')
            .responseType(graph.ResponseType.BLOB)
            .get();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = () => {
                reject(new Error('Error al leer el blob con FileReader'));
            };
        });
    } catch (error: any) {
        throw new Error('Error al obtener el avatar: ' + error.message);
    }
}