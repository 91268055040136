import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User, UsuarioSettings } from "../../interfaces/user";
import { AccountInfo } from "@azure/msal-browser";


export interface AuthState {
    status: 'checking' | 'authenticated' | 'not-authenticated';
    user: User;
    roles: string[];
    regional: boolean;
    accessToken: string;
    accessTokenGraph: string;
    currentAccount: AccountInfo;
}

const initialState: AuthState = {
    status: 'checking',
    user: {
        uId: "",
        usuarioId: 0,
        fullName: "",
        email: "",
        lastLogin: "",
        alias: "",
        foto: "",
        esSA: false,
        entidades: [],
        entidadLegalIdPreferida: null,
        monedaIdPreferida: null,
    },
    roles: [],
    regional: false,
    accessToken: "",
    accessTokenGraph: "",
    currentAccount: {} as AccountInfo,
};

const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action: PayloadAction<AuthState>) {
            state.status = 'authenticated';
            state.user = action.payload.user;
            state.roles = action.payload.roles;
            state.regional = action.payload.regional;
            state.accessToken = action.payload.accessToken;
            state.currentAccount = action.payload.currentAccount;
            state.accessTokenGraph = action.payload.accessTokenGraph;
        },
        logout(state) {
            state.status = 'not-authenticated';
            state.user = {
                uId: "",
                usuarioId: 0,
                fullName: "",
                email: "",
                lastLogin: "",
                alias: "",
                foto: "",
                esSA: false,
                entidades: [],
                entidadLegalIdPreferida: null,
                monedaIdPreferida: null,
            };
            state.roles = [];
            state.regional = false;
            state.accessToken = "";
            state.accessTokenGraph = "";
            state.currentAccount = {} as AccountInfo;
        },
        checking(state) {
            state.status = 'checking';
        },
        setAvatar(state, action: PayloadAction<string>) {
            state.user.foto = action.payload;
        },
        setUsuarioSettings(state, action: PayloadAction<UsuarioSettings>) {
            state.user.usuarioId = action.payload.usuarioId;
            state.user.entidades = action.payload.entidades;
            state.user.esSA = action.payload.esSA;
            state.user.monedaIdPreferida = action.payload.monedaIdPreferida;
            state.user.entidadLegalIdPreferida = action.payload.entidadLegalIdPreferida;

            state.regional = action.payload.entidades.length > 1;
        }
    }
});

export const { loginSuccess, logout, checking, setAvatar, setUsuarioSettings } = authReducer.actions;

export default authReducer.reducer;

