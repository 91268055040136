import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from '../../../interfaces/general';
import { ACTIVIDAD_RESERVA_DETALLE_GET, ACTIVIDAD_RESERVA_DETALLE_LOADING } from './types';
import { ActividadReserva } from '../../../interfaces/respuestas';
import { getActividadReservaBy } from '../../../helpers/reservasServices';
import { setARDData } from './actions';
import { notifications } from '../../../helpers/notifications';
import { RootState } from '../../rootReducers';

function* getByARD({ payload }: Action<ActividadReserva>): any {
    const { tipo } = yield select((state: RootState) => state.ReservasActions);
    try {
        yield put({ type: ACTIVIDAD_RESERVA_DETALLE_LOADING, payload: true });
        const { actividadReservaCabeceraId, bookingId, proveedorId, actividadId } = payload;
        const ard = yield call(getActividadReservaBy, actividadReservaCabeceraId, bookingId, proveedorId, actividadId, tipo);
        yield put(setARDData(ard));
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: ACTIVIDAD_RESERVA_DETALLE_LOADING, payload: false });
    }

}

// watcher 
function* watchGetByActividadReservaDetalle() {
    yield takeEvery(ACTIVIDAD_RESERVA_DETALLE_GET, getByARD);
}


export default function* actividadReservaDetallesSaga() {
    yield all([
        fork(watchGetByActividadReservaDetalle),
    ]);
}