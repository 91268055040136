import { all, fork, put, call, select, takeEvery } from 'redux-saga/effects';
import { RESERVAS_ACTIONS_OPEN_LETTER, RESERVAS_ACTIONS_PROCESS_ESTADOS_FUTUROS, RESERVAS_ACTIONS_OPEN_MULTI_LETTER, RESERVAS_ACTIONS_PROCESS_CURRENT_PATH, RESERVAS_ACTIONS_SET_CURRENT_PATH } from './type';
import { RootState } from '../../rootReducers';
import { notifications } from '../../../helpers/notifications';
import { postActualizarEstadoFuturoReservas } from '../../../helpers/reservasServices';
import { tipoReserva } from '../../../constants/default';
import { Action } from '../../../interfaces/general';

function* prepararParametros() {
    const { ReservasActions, ReservasDetalles, ReservasOperaciones } = yield select((state: RootState) => state);
    try {
        const { selected } = ReservasOperaciones;
        const { changeDetalle } = ReservasDetalles;
        const { tipo } = ReservasActions;
        yield call(postActualizarEstadoFuturoReservas, changeDetalle, tipo, selected.ReservaId);
        if (tipo === tipoReserva.hotel) {
            yield put({ type: RESERVAS_ACTIONS_OPEN_LETTER, payload: true });
        }
        if (tipo === tipoReserva.alimento) {
            if (selected.ReservaId === undefined) {
                yield put({ type: RESERVAS_ACTIONS_OPEN_MULTI_LETTER, payload: true });
            } else {
                yield put({ type: RESERVAS_ACTIONS_OPEN_LETTER, payload: true });
            }
        }
    } catch (error) {
        notifications({ ex: error });
    }
}

function* processCurrentPath({ payload }: Action<any>) {
    const { reservasWizard } = yield select((state: RootState) => state.Catalogos);
    const pathEstado = reservasWizard[payload];
    yield put({ type: RESERVAS_ACTIONS_SET_CURRENT_PATH, payload: pathEstado });
}

// watchers
function* watchPrepareParams() {
    yield takeEvery(RESERVAS_ACTIONS_PROCESS_ESTADOS_FUTUROS, prepararParametros);
}

function* watchSetEstadoCurrentPath() {
    yield takeEvery(RESERVAS_ACTIONS_PROCESS_CURRENT_PATH, processCurrentPath);
}

export default function* reservasActionsSagas() {
    yield all([
        fork(watchPrepareParams),
        fork(watchSetEstadoCurrentPath)
    ])
}