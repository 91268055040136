import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Mantenimiento } from '../pages/Utility/Mantenimiento';
import { Creditos } from '../pages/Utility/Creditos';
import { Prohibido } from '../pages/Utility/Prohibido';

const Login = lazy(() => import('../pages/Auth/Login'));

export const UnauthenticatedRoutes = () => {
    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/mantenimiento' element={<Mantenimiento />} />
            <Route path='/prohibido' element={<Prohibido />} />
            <Route path='/creditos' element={<Creditos />} />
            <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
    );
};
