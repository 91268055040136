import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { getProveedoresReservas } from "../../../helpers/odataApiServices";
import {
    GET_HISTORY_RESERVAS,
    GET_PROVEEDORES_RESERVAS, LOADING_HISTORICO_RESERVAS,
    LOADING_PROVEEDORES_RESERVAS, SET_HISTORY_RESERVAS,
    SET_PROVEEDORES_RESERVAS
} from "./type";
import { esObjetoVacio } from "../../../helpers/utils";
import { RootState } from "../../rootReducers";
import { BookingsState, ItemBooking } from "../../../interfaces/booking";
import { ItemProveedor, StateReservasHistorico } from "./ReservasHistoricoRedux";
import { get } from "../../../helpers/api_helper";
import { urlHistoricoReserva } from "../../../constants/api";
import { notifications } from "../../../helpers/notifications";
import { toODataString } from "@progress/kendo-data-query";
import moment from "moment-timezone";

const ReservasHistorico = (state: RootState) => state.ReservasHistorico;
const Booking = (state: RootState) => state.Booking;

const getReservas = (filtros: string, next: string) => {
    return get(`${urlHistoricoReserva}&filter=${filtros}&${next}`)
        .then(res => {
            return {
                data: res.value.map((item: any) => {
                    item.FechaCreacion = moment(item.FechaCreacion).toDate();
                    item.FechaUltimaModificacion = moment(item.FechaUltimaModificacion).toDate();
                    return item;
                }),
                total: res['@odata.count']
            }
        }
        ).catch(error => {
            notifications({ ex: error });
        });
}
const handleFilter = (ordenPedido: number, proveedorSelect: ItemProveedor, selectBookingReserva: ItemBooking) => {
    let filters: string[] = [];

    if (!esObjetoVacio(proveedorSelect)) {
        filters.push(`Reserva/Proveedor/ProveedorId eq ${proveedorSelect.proveedorId}`);
    }

    if (!esObjetoVacio(selectBookingReserva)) {
        filters.push(`Reserva/Booking/BookingId eq ${selectBookingReserva.bookingId}`);
    }

    if (ordenPedido > 0) {
        filters.push(`OrdenPedido eq ${ordenPedido}`);
    }
    return filters.join(' and ');
}

function* getProveedores(): any {
    yield put({ type: LOADING_PROVEEDORES_RESERVAS, payload: true });
    const proveedoresR = yield call(getProveedoresReservas);
    yield put({ type: SET_PROVEEDORES_RESERVAS, payload: proveedoresR });
    yield put({ type: LOADING_PROVEEDORES_RESERVAS, payload: false });
}

function* getReservasHistorico(): any {
    yield put({ type: LOADING_HISTORICO_RESERVAS, payload: true });
    const { proveedorSelect, ordenPedido, dataState }: StateReservasHistorico = yield select(ReservasHistorico);
    const { selectBookingReserva }: BookingsState = yield select(Booking);
    const filters = handleFilter(ordenPedido, proveedorSelect, selectBookingReserva);
    const reservas = yield call(getReservas, filters, toODataString(dataState));
    yield put({ type: SET_HISTORY_RESERVAS, payload: reservas });
    yield put({ type: LOADING_HISTORICO_RESERVAS, payload: false });
}

function* watchGetProveedoresReservas() {
    yield takeEvery(GET_PROVEEDORES_RESERVAS, getProveedores);
}

function* watchGetHistorialReservas() {
    yield takeEvery(GET_HISTORY_RESERVAS, getReservasHistorico);
}

export default function* reservasHistoricoSagas() {
    yield all([
        fork(watchGetProveedoresReservas),
        fork(watchGetHistorialReservas),
    ])
}