import { Suspense, useEffect } from 'react';
import { UnauthenticatedTemplate, AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import './assets/scss/theme.scss';
import '@progress/kendo-theme-default/dist/all.css';
import './assets/css/Perseverance.css';
import './assets/css/Embarcaciones.scss';
import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import ecNumbers from 'cldr-numbers-full/main/es-EC/numbers.json';
import ecLocalCurrency from 'cldr-numbers-full/main/es-EC/currencies.json';
import ecCaGregorian from 'cldr-dates-full/main/es-EC/ca-gregorian.json';
import ecDateFields from 'cldr-dates-full/main/es-EC/dateFields.json';
import esMessages from './translations/es/global.json';
import { Loading } from './common/Loading';

import { notifications } from './helpers/notifications';
import { UnauthenticatedRoutes } from './routes/UnauthenticatedRoutes';
import { AuthenticatedRoutes } from './routes/AuthenticatedRoutes';
import { AccountInfo, AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, protectedResources } from './helpers/authConfig';
import { AuthState, loginSuccess } from './store/auth/AuthReducer';
import { useDispatch, useSelector } from 'react-redux';
import { createAuthState } from './helpers/utilsUser';
import { RootState } from './store/rootReducers';
export const msalInstance = new PublicClientApplication(msalConfig);

loadMessages(esMessages, 'es-EC');
load(likelySubtags, currencyData, weekData, ecNumbers, ecLocalCurrency, ecCaGregorian, ecDateFields);

function App() {
    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state: RootState) => state.Layout);
    useEffect(() => {
        const handleRedirectResponse = async () => {
            try {
                const response: AuthenticationResult | null = await instance.handleRedirectPromise();
                let account: AccountInfo | null = null;
                let login: AuthState = {} as AuthState;
                if (response) {
                    account = response.account!;
                    const graphResponse = await msalInstance.acquireTokenSilent({
                        account: account,
                        scopes: ['User.Read'],
                    });
                    login = createAuthState(account, response.accessToken, graphResponse.accessToken);
                } else if (accounts.length > 0) {
                    // Ya hay un usuario autenticado
                    account = accounts[0];
                    const response = await instance.acquireTokenSilent({
                        account: account,
                        scopes: protectedResources.apiPerseverance.scopes,
                    });
                    const graphResponse = await msalInstance.acquireTokenSilent({
                        account: account,
                        scopes: ['User.Read'],
                    });
                    login = createAuthState(account, response.accessToken, graphResponse.accessToken);
                }
                if (account !== null) {
                    dispatch(loginSuccess(login));
                    instance.setActiveAccount(account);
                }
            } catch (error) {
                notifications({ ex: error });
            }
        };

        handleRedirectResponse();
    }, [instance, accounts, dispatch]);

    return (
        <LocalizationProvider language='es-EC'>
            <IntlProvider locale='es-EC'>
                <Suspense
                    fallback={
                        <div id='preloader'>
                            <Loading />
                        </div>
                    }
                >
                    {isAuthenticated ? (
                        <AuthenticatedTemplate>
                            <AuthenticatedRoutes />
                        </AuthenticatedTemplate>
                    ) : (
                        <UnauthenticatedTemplate>
                            <UnauthenticatedRoutes />
                        </UnauthenticatedTemplate>
                    )}
                </Suspense>
            </IntlProvider>
        </LocalizationProvider>
    );
}

export default App;
