// @flow
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SET_IS_LOAD_CATALOGOS,
  SHOW_RIGHT_SIDEBAR,
  SET_IS_AUTHENTICATED,
} from "./actionTypes"

import { changeSidebarType as changeSidebarTypeAction, changeTopbarTheme as changeTopbarThemeAction, } from "./actions"
import { agruparCatalogos, agruparListaSeleccionPorTabla, buildListaSeleccionTree, idListaSeleccion, idNombre } from "../../helpers/utils";
import { getAllListaSeleccion, getEstados, getReservasWizard } from "../../helpers/odataApiServices";
import {
  CATALOGOS_SET_ESTADO,
  CATALOGOS_SET_ID_LISTA_SELECCION,
  CATALOGOS_SET_ID_NOMBRE,
  CATALOGOS_SET_LISTASELECCION,
  CATALOGOS_SET_LISTASELECCION_AGRUPACIONES,
  CATALOGOS_SET_LISTASELECCION_DATA,
  CATALOGOS_SET_LS_TREE,
  CATALOGOS_SET_RESERVAS_WIZARD
} from "../catalogos/CatalogosTypes";
import { RootState } from '../rootReducers';
import { notifications } from "../../helpers/notifications";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute: any, value: any): any {
  if (document.body) document.body.setAttribute(attribute, value)
  return true
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass: any, action = "toggle"): any {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass)
      break
    case "remove":
      if (document.body) document.body.classList.remove(cssClass)
      break
    default:
      if (document.body) document.body.classList.toggle(cssClass)
      break
  }

  return true
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }: any) {
  try {
    if (process.env.REACT_APP_PRODUCTION === 'true') {
      yield put(changeTopbarThemeAction("dark"));
    } else {
      yield put(changeTopbarThemeAction("colored"));
    }

    document.body.removeAttribute("data-sidebar")
    document.body.removeAttribute("data-sidebar-image")
    document.body.removeAttribute("data-sidebar-size")

    yield call(changeBodyAttribute, "data-layout", layout)
  } catch (error) { }
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: width }: any) {
  try {
    if (width === "boxed") {
      yield put(changeSidebarTypeAction("icon"))
      yield call(changeBodyAttribute, "data-layout-size", width)
      yield call(changeBodyAttribute, "data-layout-scrollable", false)
    } else if (width === "scrollable") {
      yield put(changeSidebarTypeAction("default"))
      yield call(changeBodyAttribute, "data-layout-scrollable", true)
    } else {
      yield put(changeSidebarTypeAction("default"))
      yield call(changeBodyAttribute, "data-layout-size", width)
      yield call(changeBodyAttribute, "data-layout-scrollable", false)
    }
  } catch (error) { }
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: theme }: any) {
  try {
    yield call(changeBodyAttribute, "data-sidebar", theme)
  } catch (error) { }
}

/**
 * Changes the left sidebar theme Image
 * @param {*} param0
 */
function* changeLeftSidebarThemeImage({ payload: theme }: any) {
  try {
    yield call(changeBodyAttribute, "data-sidebar-image", theme)
  } catch (error) { }
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }: any) {
  try {
    yield call(changeBodyAttribute, "data-topbar", theme)
  } catch (error) { }
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }: any) {
  try {
    switch (sidebarType) {
      case "compact":
        yield call(changeBodyAttribute, "data-sidebar-size", "small")
        yield call(manageBodyClass, "sidebar-enable", "remove")
        yield call(manageBodyClass, "vertical-collpsed", "remove")
        break
      case "icon":
        yield call(changeBodyAttribute, "data-sidebar-size", "")
        yield call(changeBodyAttribute, "data-keep-enlarged", "true")
        yield call(manageBodyClass, "vertical-collpsed", "add")
        break
      case "condensed":
        yield call(manageBodyClass, "sidebar-enable", "add")
        if (window.screen.width >= 992) {
          yield call(manageBodyClass, "vertical-collpsed", "remove")
          yield call(manageBodyClass, "sidebar-enable", "remove")
          yield call(manageBodyClass, "vertical-collpsed", "add")
          yield call(manageBodyClass, "sidebar-enable", "add")
        } else {
          yield call(manageBodyClass, "sidebar-enable", "add")
          yield call(manageBodyClass, "vertical-collpsed", "add")
        }
        break
      default:
        yield call(changeBodyAttribute, "data-sidebar-size", "")
        yield call(manageBodyClass, "sidebar-enable", "remove")
        if (!isMobile)
          yield call(manageBodyClass, "vertical-collpsed", "remove")
        break
    }
  } catch (error) { }
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "add")
  } catch (error) { }
}
/**
 * CARGA DE CATALOS EN SEGUNDO PLANO
 */

function* loadCatalogos(): any {
  const { Layout: { isLoadCatalogos, isAuthenticated } } = yield select((state: RootState) => state);
  if (!isLoadCatalogos && isAuthenticated) {
    try {
      const catalogoListaSeleccion = yield call(getAllListaSeleccion);
      const listasById = yield call(idListaSeleccion, catalogoListaSeleccion.value);
      yield put({ type: CATALOGOS_SET_ID_LISTA_SELECCION, payload: listasById });
      yield put({ type: CATALOGOS_SET_LISTASELECCION_DATA, payload: catalogoListaSeleccion.value });
      const listaSeleccionAgrupado = agruparCatalogos(catalogoListaSeleccion.value);
      yield put({ type: CATALOGOS_SET_LISTASELECCION, payload: listaSeleccionAgrupado });
      const listaSeleccionAgrupaciones = yield call(agruparListaSeleccionPorTabla, listaSeleccionAgrupado);
      yield put({ type: CATALOGOS_SET_LISTASELECCION_AGRUPACIONES, payload: listaSeleccionAgrupaciones });
      const listaTree = yield call(buildListaSeleccionTree, catalogoListaSeleccion.value);
      yield put({ type: CATALOGOS_SET_LS_TREE, payload: listaTree });
      const catalogoEstados = yield call(getEstados);
      const estadosAgrupado = agruparCatalogos(catalogoEstados.value);
      yield put({ type: CATALOGOS_SET_ESTADO, payload: estadosAgrupado });
      const reservasWizard = yield call(getReservasWizard, estadosAgrupado);
      yield put({ type: CATALOGOS_SET_RESERVAS_WIZARD, payload: reservasWizard });
      const idEstadoNombre = yield call(idNombre, catalogoEstados.value, reservasWizard);
      yield put({ type: CATALOGOS_SET_ID_NOMBRE, payload: idEstadoNombre });
      yield put({ type: SET_IS_LOAD_CATALOGOS, payload: true });
    } catch (error) {
      notifications({ ex: error });
    }
  }
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, changeLayout)
}

export function* watchChangeLayoutWidth() {
  yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth)
}

export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme)
}

export function* watchChangeLeftSidebarThemeImage() {
  yield takeEvery(CHANGE_SIDEBAR_THEME_IMAGE, changeLeftSidebarThemeImage)
}

export function* watchChangeLeftSidebarType() {
  yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType)
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme)
}

export function* watchShowRightSidebar() {
  yield takeEvery(SHOW_RIGHT_SIDEBAR, showRightSidebar)
}

export function* watchLoadCatalogos() {
  yield takeEvery(SET_IS_AUTHENTICATED, loadCatalogos);
}

function* LayoutSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLeftSidebarThemeImage),
    fork(watchChangeLeftSidebarType),
    fork(watchShowRightSidebar),
    fork(watchChangeTopbarTheme),
    fork(watchLoadCatalogos),
  ])
}

export default LayoutSaga
